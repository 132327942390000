<template>
  <form @submit.prevent="goToNextRoute()" class="relative">
    <div class="relative">
      <div class="text-lg text-gray-600 font-montserrat">
        How much are you sending?
      </div>
      <div class="block my-4 border-b">
        <div class="block border-2 rounded-md overflow-hidden mb-4">
          <div class="flex items-center">
            <div class="flex-grow mx-4">
              <div class="text-sm mt-2 text-gray-700 font-montserrat">
                You send
              </div>

              <CurrencyInput
                required
                placeholder="0.00"
                class="
                  text-xl
                  py-1
                  w-full
                  outline-none
                  font-inter
                  appearance-none
                "
                v-model="sendAmount"
                @keyup="
                  handleCalculatePayoutAmount(
                    isSameCountry(
                      senderCurrencyDetails.name,
                      beneficiaryCurrencyDetails.name,
                    ),
                  )
                "
                :options="setCurrencyOptionsForSender()"
              />
            </div>

            <div
              class="text-lg px-4 py-5 rounded-md bg-gray-800 text-yellow-300"
            >
              {{ userDetails.country.currency }}
            </div>
          </div>
        </div>
        <div class="block border-2 rounded-md overflow-hidden">
          <div class="flex items-center">
            <div class="flex-grow mx-4">
              <div class="text-sm mt-2 text-gray-700 font-montserrat">
                Recipient receives
              </div>
              <CurrencyInput
                required
                class="
                  text-xl
                  py-1
                  w-full
                  outline-none
                  font-inter
                  appearance-none
                "
                placeholder="0.00"
                v-model="payoutAmount"
                @keyup="
                  handleCalculateSendAmount(
                    isSameCountry(
                      senderCurrencyDetails.name,
                      beneficiaryCurrencyDetails.name,
                    ),
                  )
                "
                :options="setCurrencyOptionsForRecipient()"
              />
            </div>
            <div
              class="text-lg px-4 py-5 rounded-md bg-gray-800 text-yellow-300"
            >
              {{ beneficiaryCurrencyDetails.currency }}
            </div>
          </div>
        </div>
        <div class="block ml-6 border-l px-6 py-6">
          <div class="text-xs text-gray-400 font-inter mb-4">
            Rate: 1 {{ userDetails.country.currency }} =
            {{
              calculateExchangeRate(
                isSameCountry(
                  senderCurrencyDetails.name,
                  beneficiaryCurrencyDetails.name,
                ),
                senderCurrencyDetails.marketRates,
                getRecipientExchangeRates(),
              )
            }}
            {{ beneficiaryCurrencyDetails.currency }}
          </div>
          <div class="block py-2">
            <div class="text-sm text-gray-700 font-montserrat">
              Amount to be deducted
            </div>
            <div
              class="flex text-xl py-1 w-full outline-none font-inter space-x-2"
            >
              <div>{{ userDetails.country.currency }}</div>
              <CurrencyInput
                disabled
                v-model="payAmount"
                :options="setCurrencyOptionsForSender()"
                class="bg-white placeholder-gray-400"
                :class="{ 'text-gray-400': payAmount === 0.0 }"
                placeholder="0.00"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="block py-4">
        <button
          type="submit"
          class="
            w-full
            bg-yellow-300
            px-4
            py-2
            rounded-md
            font-montserrat font-medium
          "
        >
          Continue
        </button>
      </div>
    </div>
  </form>
</template>

<script type="text/javascript">
import { ref } from '@vue/reactivity'
import definedConstants from '@/composables/definedConstants'
import utils from '@/composables/utils'
import { computed } from '@vue/runtime-core'
import { useStore } from 'vuex'

import { useRouter, useRoute } from 'vue-router'
import CurrencyInput from '@/components/partials/CurrencyInput'
import createTransaction from '@/composables/createTransaction'

export default {
  components: { CurrencyInput },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const store = useStore()
    //amount to sent in sender's currency
    const { isSameCountry } = utils()
    const userDetails = computed(() => store.state.auth.userDetails)
    const transactionOnCreation = computed(
      () => store.state.transactions.transactionOnCreation,
    )
    const payAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.payAmount,
    )
    //amount beneficiary receives in beneficiary's currency
    const payoutAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.payoutAmount,
    )

    const sendAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.sendAmount,
    )

    const senderCurrencyDetails = computed(
      () => store.state.countries.senderCurrencyDetails,
    )

    const beneficiaryCurrencyDetails = computed(
      () => store.state.beneficiary.beneficiaryCurrencyDetails,
    )

    const { currencyOptions } = definedConstants()

    const setCurrencyOptionsForSender = () => {
      return {
        ...currencyOptions,
        currency: userDetails.value.country.currency,
      }
    }
    const setCurrencyOptionsForRecipient = () => {
      return {
        ...currencyOptions,
        currency: beneficiaryCurrencyDetails.value.currency,
      }
    }

    const getRecipientExchangeRates = () => {
      return beneficiaryCurrencyDetails.value.rates
    }

    const { setSendAmount, setPayoutAmount, calculateExchangeRate } =
      createTransaction()

    const handleCalculatePayoutAmount = (isSameCountry) => {
      const { amountToPay, AmountToPayout, transactionObject } =
        setPayoutAmount(
          isSameCountry,
          sendAmount.value,
          senderCurrencyDetails.value,
          beneficiaryCurrencyDetails.value,
          transactionOnCreation.value,
        )
      payAmount.value = amountToPay
      payoutAmount.value = AmountToPayout
      store.dispatch('transactions/buildTransactionObject', transactionObject)
    }

    const handleCalculateSendAmount = (isSameCountry) => {
      const { amountToPay, AmountToSend, transactionObject } = setSendAmount(
        isSameCountry,
        senderCurrencyDetails.value,
        payoutAmount.value,
        beneficiaryCurrencyDetails.value,
        transactionOnCreation.value,
      )
      payAmount.value = amountToPay
      sendAmount.value = AmountToSend
      store.dispatch('transactions/buildTransactionObject', transactionObject)
    }

    const goToNextRoute = () => {
      if (route.name === 'sendmoney-amount') {
        router.push({ name: 'sendmoney-review' })
      }
      if (route.name === 'sendmoney-review') {
        router.push({ name: 'sendmoney-pay' })
      }
    }

    return {
      payAmount,
      sendAmount,
      userDetails,
      payoutAmount,
      goToNextRoute,
      isSameCountry,
      calculateExchangeRate,
      senderCurrencyDetails,
      getRecipientExchangeRates,
      handleCalculateSendAmount,
      beneficiaryCurrencyDetails,
      handleCalculatePayoutAmount,
      setCurrencyOptionsForSender,
      setCurrencyOptionsForRecipient,
    }
  },
}
</script>
