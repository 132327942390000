import axios from 'axios'
import definedConstants from '@/composables/definedConstants'

const { apiBaseURL } = definedConstants()

const state = () => ({
  loading: false,
  transactions: {},
  transactionOnCreation:
    sessionStorage['transactionOnCreation'] === undefined
      ? null
      : JSON.parse(sessionStorage['transactionOnCreation']),
  transactionResponse: null,
  error: null,
  success: '',
  receiptDetails:
    sessionStorage['receiptDetails'] === undefined
      ? null
      : JSON.parse(sessionStorage['receiptDetails']),
  transaction:
    sessionStorage['transaction'] === undefined
      ? []
      : JSON.parse(sessionStorage['transaction']),
  accordionIsOpenArray: [],
  takeMeToVerify: false,
})

const actions = {
  async fetchAllCustomerTransactions({ commit }, payload) {
    const token = payload.token
    const userID = payload.userID
    const jwt = payload.jwt
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        JWTToken: jwt,
        Accept: 'application/json',
      },
    }

    try {
      const response = await axios.get(
        `${apiBaseURL}/v1/transactions/${userID}
        `,
        config,
      )
      if (response.data.responseCode === 200) {
        commit('setTransactions', response.data.data)
        commit('setAccordionIsOpenArray', response.data.data)
      }
    } catch (error) {
      console.log(error)
    }
  },

  buildTransactionObject({ commit }, payload) {
    commit('buildTransactionObject', payload)
  },
  async createTransaction({ commit }, payload) {
    try {
      const response = await axios.post(
        `${apiBaseURL}/v1/transaction/create`,
        payload.body,
        payload.config,
      )
      commit('setLoadingFalse')
      if (response.data.responseCode === 202) {
        commit('setReceipt', {
          transactionId: response.data.transactionId,
          payAmount: payload.body.payAmount,
        })
        commit('setSuccess', {
          component: 'createTransaction',
          data: response.data,
        })
      } else {
        commit('setError', {
          component: 'createTransaction',
          data: response.data,
        })
      }
      if (response.data.responseStatus === '-1') {
        commit('setError', {
          component: 'createTransaction',
          data: response.data,
        })
      }

      if (response.data.responseCode === '350') {
        commit('setTakeMeToVerifyTrue')
      }
    } catch (error) {
      commit('setLoadingFalse')
      if (
        JSON.stringify(error)
          .toLowerCase()
          .includes('Network Error'.toLowerCase())
      ) {
        console.log('There was a network error.')
      }
    }
  },
  async fetchTransactionDetails({ commit }, payload) {
    const { transactionID, config } = payload
    try {
      const response = await axios.get(
        `${apiBaseURL}/v1/transactions/${transactionID}`,
        config,
      )
      if (response.data.responseStatus === '-1') {
        commit('setError', {
          component: 'createTransaction',
          data: response.data,
        })
        setTimeout(() => {
          commit('setError', null)
        }, 4000)
      }
    } catch (error) {
      console.log(error)
    }
  },
  setLoadingTrue({ commit }) {
    commit('setLoadingTrue')
  },
  storeTransaction({ commit }, payload) {
    commit('storeTransaction', payload)
  },
  toggleAccordion({ commit }, payload) {
    commit('toggleAccordion', payload)
  },
  setAccordionIsOpenArray({ commit }, payload) {
    commit('setAccordionIsOpenArray', payload)
  },
  removeError({ commit }) {
    commit('setError', null)
  },
}

const mutations = {
  setTransactions(state, payload) {
    state.transactions = payload
  },
  buildTransactionObject(state, payload) {
    sessionStorage['transactionOnCreation'] = JSON.stringify(payload)
    state.transactionOnCreation = JSON.parse(
      sessionStorage['transactionOnCreation'],
    )
  },

  setLoadingTrue(state) {
    state.loading = true
  },
  setLoadingFalse(state) {
    state.loading = false
  },
  setError(state, payload) {
    state.error = payload
  },
  setSuccess(state, payload) {
    state.success = payload
  },
  setReceipt(state, payload) {
    const receipt = payload.transactionId
    const payAmount = payload.payAmount
    const receiptDetails = {
      receipt,
      payAmount,
    }

    sessionStorage['receiptDetails'] = JSON.stringify(receiptDetails)
    state.receiptDetails = JSON.parse(sessionStorage['receiptDetails'])
  },
  storeTransaction(state, payload) {
    sessionStorage['transaction'] = JSON.stringify(payload)
    state.transaction = JSON.parse(sessionStorage['transaction'])
  },

  setAccordionIsOpenArray(state, payload) {
    if (payload.length > 0) {
      let accordionIsOpenArray = []
      for (let i = 0; i <= payload.length; i++) {
        accordionIsOpenArray.push({ i, accordionIsOpen: false })
      }
      state.accordionIsOpenArray = accordionIsOpenArray
    }
  },

  toggleAccordion(state, payload) {
    let accordionIsOpen = state.accordionIsOpenArray
    accordionIsOpen[payload.index].accordionIsOpen = payload.openAccordion
    state.accordionIsOpenArray = accordionIsOpen
  },

  setTakeMeToVerifyTrue(state) {
    state.takeMeToVerify = true
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
