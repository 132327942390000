import { ref } from '@vue/runtime-core'

const autologout = () => {
  const events = ref([
    'click',
    'mousemove',
    'mousedown',
    'scroll',
    'keypress',
    'load',
  ])

  let warningTimer = null
  let logoutTimer = null
  const warningZone = ref(false)
  const logoutZone = ref(false)

  const warningMessage = () => {
    warningZone.value = true
  }

  const logoutUser = () => {
    logoutZone.value = true
  }
  const setTimers = () => {
    warningTimer = setTimeout(warningMessage, 1 * 1000)
    logoutTimer = setTimeout(logoutUser, 4 * 1000)
  }

  const resetTimers = () => {
    clearTimeout(warningTimer)
    clearTimeout(logoutTimer)
    setTimers()
  }

  const addEvents = () => {
    events.value.forEach((event) => {
      window.addEventListener(event, resetTimers)
    })
  }

  const clearEvents = () => {
    events.value.forEach((event) => {
      window.removeEventListener(event, resetTimers)
    })

    resetTimers()
  }

  const cardWindow = ref('')

  function refreshParent() {
    window.opener.location.reload()
  }

  const goToAddCard = (data) => {
    cardWindow.value = window.open('', 'cardWindow', '')
    if (cardWindow.value !== undefined && cardWindow.value !== null) {
      cardWindow.value.document.write(data)
      if (
        cardWindow.value.document.getElementsByName('frmLaunch') !== undefined
      ) {
        cardWindow.value.document.frmLaunch.submit()
      }
    }
  }

  return {
    addEvents,
    logoutZone,
    warningZone,
    clearEvents,
    goToAddCard,
    refreshParent,
  }
}
export default autologout
