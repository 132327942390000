<template>
  <div class="absolute w-full h-full left-0 top-0 bg-gray-50">
    <div class="top-0 bottom-0 overflow-auto">
      <!-- start header -->
      <Head />
      <!-- End header -->
      <div class="relative mb-2 px-4 bg-black pb-4">
        <CalculateExchangeRate />
        <Utilities />
      </div>
      <div class="block py-4">
        <div class="text-xs px-4 pt-4 text-gray-400 font-bold font-montserrat">
          RECENT TRANSACTIONS
        </div>
      </div>
      <Transactions />
    </div>
    <RecipientsModal v-if="showBeneficiariesModal" />
    <AddRecipientModal v-if="showAddBeneficiaryModal" />
  </div>
</template>
<script>
import RecipientsModal from '@/components/RecipientsModal.vue'
import AddRecipientModal from '@/components/AddRecipientModal.vue'
import CalculateExchangeRate from './CalculateExchangeRate.vue'
import Transactions from '@/components/Transactions.vue'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
import Head from './Head.vue'
import Utilities from './Utilities.vue'

export default {
  components: {
    Head,
    Utilities,
    RecipientsModal,
    Transactions,
    AddRecipientModal,
    CalculateExchangeRate,
  },
  setup() {
    const store = useStore()
    const userDetails = computed(() => store.state.auth.userDetails)

    const showBeneficiariesModal = computed(
      () => store.state.beneficiary.showBeneficiariesModal,
    )
    const showAddBeneficiaryModal = computed(
      () => store.state.beneficiary.showAddBeneficiaryModal,
    )
    //boot intercom
    const bootIntercom = () => {
      if (userDetails.value !== null) {
        window.Intercom('boot', {
          app_id: 'avv4lma1',
          email: userDetails.value.name,
          user_id: userDetails.value.userID,
          created_at: 1234567890,
        })
      }
    }
    bootIntercom()

    return {
      userDetails,
      showBeneficiariesModal,
      showAddBeneficiaryModal,
    }
  },
}
</script>
