<template>
  <div
    class="fixed left-0 top-0 w-full h-full z-10 transition-all"
    style="z-index: 2147483004"
  >
    <div
      class="absolute bottom-0 left-0 right-0 top-0 bg-black bg-opacity-50"
      @click="closeAddCardModal()"
    ></div>
    <div
      class="
        absolute
        bottom-0
        md:top-20 md:bottom-auto
        left-0
        right-0
        bg-white
        rounded-lg
        overflow-auto
        max-w-xl
        mx-auto
        animate__animated animate__fadeInDown
      "
    >
      <div
        class="
          block
          py-4
          px-4
          font-montserrat font-medium
          text-xl text-gray-600
        "
      >
        Add payment details
      </div>
      <AddCard :redirectPath="currentRoutePath" />
    </div>
  </div>
</template>

<script>
import AddCard from '@/components/AddCard.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  components: { AddCard },
  setup() {
    const store = useStore()
    const router = useRouter()
    const closeAddCardModal = () => {
      store.dispatch('paymentInfo/closeAddCardModal')
    }
    const currentRoutePath = router.currentRoute.value.path

    return {
      currentRoutePath,
      closeAddCardModal,
    }
  },
}
</script>
