import axios from 'axios'
import definedConstants from '@/composables/definedConstants'

const { apiBaseURL } = definedConstants()

const state = () => ({
  paymentInfo:
    sessionStorage['paymentInfo'] === undefined
      ? null
      : JSON.parse(sessionStorage['paymentInfo']),
  error: '',
  formErrors: null,
  success: '',
  loading: false,
  status: '',
  showAddCardModal: false,
  showCVVModal: false,
  CVC:
    localStorage['recipientVerifyCode'] === undefined
      ? null
      : localStorage.getItem('recipientVerifyCode'),
})

const actions = {
  async addPaymentInfo({ commit }, payload) {
    try {
      const response = await axios.post(
        `${apiBaseURL}/v1/payment/info/create`,
        payload.body,
        payload.config,
      )
      commit('setLoadingFalse')
      if (response.data.responseCode === 200) {
        commit('setSuccess', {
          component: 'addPaymentInfo',
          data: response.data,
        })
      } else {
        commit('setError', {
          component: 'addPaymentInfo',
          message: response.data.message,
          code: response.data.responseCode,
        })
      }
    } catch (error) {
      //   Error: timeout of 0ms exceeded
      // at createError (createError.js?2d83:16)
      // at XMLHttpRequest.handleTimeout (xhr.js?b50d:96)
      commit('setLoadingFalse')
      console.log(error)
    }
  },
  async getPaymentInfo({ commit }, payload) {
    const { userID, config } = payload
    try {
      const response = await axios.get(
        `${apiBaseURL}/v1/payment/info/${userID}`,
        config,
      )
      if (response.data.code === 202) {
        commit('setPaymentInfo', response.data)
      }
    } catch (error) {
      console.log(error)
    }
  },
  async removePaymentInfo({ commit }, payload) {
    const { body, config } = payload
    try {
      const response = await axios.post(
        `${apiBaseURL}/v1/payment/info/remove`,
        body,
        config,
      )
      commit('setLoadingFalse')
      if (response.data.code === 202) {
        commit('removePaymentInfo')
      }
    } catch (error) {
      commit('setLoadingFalse')
      console.log(error)
    }
  },
  async setLoadingTrue({ commit }) {
    commit('setLoadingTrue')
  },
  setStatus({ commit }, payload) {
    commit('setStatus', payload)
    setTimeout(() => {
      commit('setStatus', '')
    }, 2000)
  },
  openAddCardModal({ commit }) {
    commit('openAddCardModal')
  },
  closeAddCardModal({ commit }) {
    commit('closeAddCardModal')
  },
  openCVVModal({ commit }) {
    commit('openCVVModal')
  },
  closeCVVModal({ commit }) {
    commit('closeCVVModal')
  },
  setCVC({ commit }, payload) {
    commit('setCVC', payload)
  },
  unsetCVC({ commit }) {
    commit('unsetCVC')
  },
}

const mutations = {
  setPaymentInfo(state, payload) {
    sessionStorage['paymentInfo'] = JSON.stringify(payload)
    state.paymentInfo = JSON.parse(sessionStorage['paymentInfo'])
  },
  setSuccess(state, payload) {
    state.success = payload
  },
  setError(state, payload) {
    state.error = payload
  },
  setFormErrors(state, payload) {
    state.formErrors = payload
  },
  clearFormFieldError(state, payload) {
    if (state.formErrors !== null) {
      if (state.formErrors.errors !== undefined) {
        if (
          Object.prototype.hasOwnProperty.call(state.formErrors.errors, payload)
        ) {
          delete state.formErrors.errors[payload]
        }
      }
    }
  },
  setLoadingTrue(state) {
    state.loading = true
  },
  setLoadingFalse(state) {
    state.loading = false
  },
  removePaymentInfo(state) {
    sessionStorage.removeItem('paymentInfo')
    state.paymentInfo = null
  },
  setStatus(state, payload) {
    state.status = payload
  },
  openAddCardModal(state) {
    state.showAddCardModal = true
  },
  closeAddCardModal(state) {
    state.showAddCardModal = false
  },
  openCVVModal(state) {
    state.showCVVModal = true
  },
  closeCVVModal(state) {
    state.showCVVModal = false
  },
  setCVC(state, payload) {
    localStorage.setItem('recipientVerifyCode', payload)
    state.CVC = localStorage.getItem('recipientVerifyCode')
  },
  unsetCVC(state) {
    localStorage.removeItem('recipientVerifyCode')
    state.CVC = null
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
