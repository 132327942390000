<template>
  <div class="md:block hidden">
    <Onboard v-if="showOnBoard" />
    <div v-else class="block relative grid grid-cols-4 px-6 py-6">
      <div class="col-span-3 pr-12">
        <QuickActions />
        <RecentRecipients />
      </div>
      <div class="col">
        <RatesCalculator />
        <HowToSend />
      </div>
    </div>
  </div>
</template>

<script>
import utils from '@/composables/utils'
import { useStore } from 'vuex'
import { computed, ref } from '@vue/reactivity'
import { watchEffect } from '@vue/runtime-core'
import Onboard from './Onboard.vue'
import QuickActions from './QuickActions.vue'
import RecentRecipients from './RecentRecipients.vue'
import RatesCalculator from './RatesCalculator.vue'
import HowToSend from './HowToSend.vue'

export default {
  components: {
    Onboard,
    QuickActions,
    RecentRecipients,
    RatesCalculator,
    HowToSend,
  },
  setup() {
    const store = useStore()
    const profileProgress = computed(() => store.state.profile.profileProgress)
    const userDetails = computed(() => store.state.auth.userDetails)
    const showOnBoard = ref(true)

    const profileDetails = computed(() => store.state.auth.profileDetails)

    const token = computed(() => store.state.auth.token)

    const { setRequestConfig } = utils()

    const config = setRequestConfig(token.value, userDetails.value.jwt)

    const fetchProfileDetails = () => {
      const userID = userDetails.value.userID
      const payload = {
        userID,
        config,
      }
      store.dispatch('auth/fetchProfileDetails', payload)
    }
    fetchProfileDetails()

    const setProfileProgress = async () => {
      let accountVerified,
        paymentCardAdded,
        accountActivated = false

      let progress = 0
      if (profileDetails.value !== null) {
        if (profileDetails.value.verified === 1) {
          accountActivated = true
          progress = 33
        }
        if (profileDetails.value.approved === 2) {
          accountVerified = true
        }
        if (accountActivated && accountVerified) {
          progress = 66
        }
        if (profileDetails.value.paymentDetailsSet) paymentCardAdded = true
        if (accountActivated && accountVerified && paymentCardAdded) {
          showOnBoard.value = false
          progress = 100
        }
        if (!paymentCardAdded) {
          if (
            accountActivated &&
            accountVerified &&
            profileDetails.value.paymentDetailsDeleted > 0
          ) {
            showOnBoard.value = false
          }
        }
      }
      const payload = {
        accountActivated,
        accountVerified,
        paymentCardAdded,
        profileProgress: progress,
      }
      await store.dispatch('profile/updateProfileProgress', payload)
    }

    watchEffect(() => {
      setProfileProgress()
    })

    return {
      showOnBoard,
      profileProgress,
    }
  },
}
</script>
