const createTransaction = () => {
  const buildTransactionObject = (
    transaction,
    payoutAmount,
    payAmount,
    sendAmount,
  ) => {
    const transactionOnCreation = {
      ...transaction,
      payoutAmount: parseFloat(payoutAmount),
      payAmount: parseFloat(payAmount),
      sendAmount: parseFloat(sendAmount),
    }
    return transactionOnCreation
  }

  const calculateExchangeRate = (
    isSameCountry,
    senderMarketRate,
    recipientExchangeRate,
  ) => {
    if (isSameCountry) {
      return 1
    }
    senderMarketRate = parseFloat(senderMarketRate)
    recipientExchangeRate = parseFloat(recipientExchangeRate)
    const result = (1 / senderMarketRate) * recipientExchangeRate
    return Math.trunc(result * 100) / 100
  }

  const calculateSendAmount = (payoutAmount, rate) => {
    const sendAmount = parseFloat(payoutAmount) / rate
    return Math.trunc(sendAmount * 100) / 100
  }

  const calculatePayoutAmount = (sendAmount, rate) => {
    const payoutAmount = parseFloat(sendAmount) * rate
    return Math.trunc(payoutAmount * 100) / 100
  }

  const calculatePayAmount = (sendAmount, fee) => {
    const payAmount = parseFloat(sendAmount) + parseFloat(fee)
    return Math.trunc(payAmount * 100) / 100
  }

  //TODO rename this to more meaningful name
  const getBeneficiaryExchangeRate = (country, currencies) => {
    const beneficiaryExchangeRate = currencies.filter((item) => {
      return item.isoCode === country
    })
    return beneficiaryExchangeRate[0]
  }

  const setRecipientExchangeRates = (
    beneficiaryCurrencyDetails,
    defaultCountryDetails,
  ) => {
    if (null !== beneficiaryCurrencyDetails) {
      return beneficiaryCurrencyDetails.rates
    }
    return defaultCountryDetails.rates
  }

  const setRecipientExchangeCountryName = (
    beneficiaryCurrencyDetails,
    defaultCountryDetails,
  ) => {
    if (null !== beneficiaryCurrencyDetails) {
      return beneficiaryCurrencyDetails.name
    }
    return defaultCountryDetails.name
  }
  const setBeneficiaryCurrencyDetails = (
    beneficiaryCurrencyDetails,
    defaultCountryDetails,
  ) => {
    if (beneficiaryCurrencyDetails !== null) {
      return beneficiaryCurrencyDetails
    }
    return defaultCountryDetails
  }

  const setSendAmount = (
    isSameCountry,
    senderCurrencyDetails,
    payoutAmount,
    beneficiaryCurrencyDetails,
    transactionOnCreation,
  ) => {
    let sendAmount,
      payAmount = null
    if (payoutAmount) {
      sendAmount = calculateSendAmount(
        payoutAmount,
        calculateExchangeRate(
          isSameCountry,
          senderCurrencyDetails.marketRates,
          beneficiaryCurrencyDetails.rates,
        ),
      )
      payAmount = calculatePayAmount(sendAmount, senderCurrencyDetails.fee)
    } else {
      payAmount = 0
      sendAmount = null
    }

    const transactionObject = buildTransactionObject(
      transactionOnCreation,
      payoutAmount,
      payAmount,
      sendAmount,
    )

    return {
      amountToPay: payAmount,
      AmountToSend: sendAmount,
      transactionObject,
    }
  }

  const setPayoutAmount = (
    isSameCountry,
    sendAmount,
    senderCurrencyDetails,
    beneficiaryCurrencyDetails,
    transactionOnCreation,
  ) => {
    let payoutAmount,
      payAmount = null
    if (sendAmount) {
      payoutAmount = calculatePayoutAmount(
        sendAmount,
        calculateExchangeRate(
          isSameCountry,
          senderCurrencyDetails.marketRates,
          beneficiaryCurrencyDetails.rates,
        ),
      )
      payAmount = calculatePayAmount(sendAmount, senderCurrencyDetails.fee)
    } else {
      payAmount = 0
      payoutAmount = null
    }
    const transactionObject = buildTransactionObject(
      transactionOnCreation,
      payoutAmount,
      payAmount,
      sendAmount,
    )
    return {
      amountToPay: payAmount,
      AmountToPayout: payoutAmount,
      transactionObject,
    }
  }

  return {
    setSendAmount,
    setPayoutAmount,
    calculateExchangeRate,
    buildTransactionObject,
    setRecipientExchangeRates,
    getBeneficiaryExchangeRate,
    setBeneficiaryCurrencyDetails,
    setRecipientExchangeCountryName,
  }
}
export default createTransaction
