<template>
  <div class="relative md:p-6 p-4">
    <div class="relative bg-white border md:py-8 md:px-12 p-6">
      <div class="flex items-center">
        <div class="flex-grow">
          <div class="text-xl font-montserrat font-medium">
            Get started with Helasend
          </div>
          <div class="text-sm opacity-50">Follow these steps to send money</div>
        </div>
        <div
          class="md:block flex-shrink cursor-pointer"
          @click="showOnboard = !showOnboard"
        >
          <svg
            v-show="showOnboard == false"
            width="36"
            height="36"
            fill="#aaa"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M5.29 7.293l-.01 0c.39-.4 1.02-.4 1.41-.01l3.29 3.29 3.29-3.293 -.01 0c.38-.4 1.01-.41 1.41-.03 .39.38.4 1.01.02 1.41 -.01 0-.02.01-.03.02l-4 4 0-.01c-.4.39-1.03.39-1.42 0l-4-4 0 0c-.4-.4-.4-1.03-.01-1.42Z"
            />
          </svg>
          <svg
            v-show="showOnboard == true"
            width="36"
            height="36"
            fill="#aaa"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M14.7 12.7l0-.01c-.4.39-1.03.39-1.42 0l-3.3-3.3 -3.293 3.29 0-.01c-.4.38-1.04.37-1.42-.03 -.38-.39-.38-1.01-.01-1.39l4-4 -.01 0c.39-.4 1.02-.4 1.41-.01l4 4 -.01-.01c.39.39.39 1.02 0 1.41Z"
            />
          </svg>
        </div>
      </div>
      <div class="flex my-6 items-center" v-show="showOnboard == true">
        <div class="flex-shrink hidden md:block">
          <div class="relative w-56 h-56 grid place-content-center">
            <!-- 33% -->
            <svg
              v-if="profileProgress === 33"
              xmlns="http://www.w3.org/2000/svg"
              width="180"
              height="180"
              viewBox="0 0 140 140"
            >
              <g
                id="Group_89"
                data-name="Group 89"
                transform="translate(-2624 -76) rotate(-90)"
              >
                <circle
                  id="Ellipse_14"
                  data-name="Ellipse 14"
                  cx="62"
                  cy="62"
                  r="62"
                  transform="translate(-208 2632)"
                  fill="none"
                  stroke="#ddd"
                  stroke-linecap="round"
                  stroke-width="16"
                />
                <circle
                  id="Ellipse_13"
                  data-name="Ellipse 13"
                  cx="62"
                  cy="62"
                  r="62"
                  transform="translate(-208 2632)"
                  fill="none"
                  stroke="#777"
                  stroke-linecap="round"
                  stroke-width="16"
                  stroke-dasharray="130 389"
                />
              </g>
            </svg>

            <!-- 66% -->
            <svg
              v-if="profileProgress === 66"
              xmlns="http://www.w3.org/2000/svg"
              width="180"
              height="180"
              viewBox="0 0 140 140"
            >
              <g
                id="Group_89"
                data-name="Group 89"
                transform="translate(-2624 -76) rotate(-90)"
              >
                <circle
                  id="Ellipse_14"
                  data-name="Ellipse 14"
                  cx="62"
                  cy="62"
                  r="62"
                  transform="translate(-208 2632)"
                  fill="none"
                  stroke="#ddd"
                  stroke-linecap="round"
                  stroke-width="16"
                />
                <circle
                  id="Ellipse_13"
                  data-name="Ellipse 13"
                  cx="62"
                  cy="62"
                  r="62"
                  transform="translate(-208 2632)"
                  fill="none"
                  stroke="#777"
                  stroke-linecap="round"
                  stroke-width="16"
                  stroke-dasharray="260 389"
                />
              </g>
            </svg>

            <div
              class="
                absolute
                grid
                place-content-center
                w-56
                h-56
                text-4xl
                top-0
                left-0
              "
            >
              {{ profileProgress }}%
            </div>
          </div>
        </div>
        <div class="flex-grow md:pl-4">
          <div
            class="
              flex
              items-center
              border-b
              py-4
              md:px-3
              opacity-40
              hover:bg-gray-50
            "
            :class="{ 'opacity-40': accountActivated }"
          >
            <div
              class="
                p-4
                rounded-full
                bg-green-500
                border border-gray-200
                grid
                place-content-center
              "
              :class="{ 'bg-green-500': accountActivated }"
            >
              <SVGs :name="'check'" v-if="accountActivated" />
            </div>
            <div class="pl-6">
              <div class="text-md font-medium font-montserrat">
                Activate your account
              </div>
              <div v-if="accountActivated" class="text-sm mt-1 opacity-50">
                Your account has been activated
              </div>
              <div v-else class="text-sm mt-1 opacity-50">
                Fill out your profile to complete your account activation
              </div>
            </div>
          </div>
          <div
            class="
              flex
              items-center
              border-b
              py-4
              md:px-3
              hover:bg-gray-50
              cursor-pointer
            "
            :class="{ 'opacity-40': accountVerified }"
            @click="verify()"
          >
            <div
              class="
                p-4
                rounded-full
                border border-gray-300
                grid
                place-content-center
              "
              :class="{ 'bg-green-500': accountVerified }"
            >
              <svg
                v-if="!accountVerified"
                class="inline-block"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 5c1.93 0 3.5 1.56 3.5 3.5 0 1.93-1.57 3.5-3.5 3.5 -1.94 0-3.5-1.57-3.5-3.5C5.5 6.56 7.06 5 9 5Zm0 8.75c3.86 0 7 1.56 7 3.5V19H2v-1.75c0-1.94 3.13-3.5 7-3.5Zm8-1.09l-2.75-3 1.16-1.17 1.58 1.58 3.58-3.59 1.16 1.41 -4.75 4.74Z"
                />
              </svg>
              <SVGs :name="'check'" v-if="accountVerified" />
            </div>
            <div class="pl-6">
              <div class="text-md font-medium font-montserrat">
                Get Verified
              </div>
              <div v-if="accountVerified" class="text-sm mt-1 opacity-50">
                Your account is verified
              </div>
              <div v-else class="text-sm mt-1 opacity-50">
                Fill out your profile to complete your account verification
              </div>
            </div>
          </div>
          <div
            @click="openAddCardModal()"
            class="
              flex
              items-center
              py-4
              md:px-3
              hover:bg-gray-50
              cursor-pointer
            "
            :class="{ 'opacity-40': paymentCardAdded }"
          >
            <div
              class="
                p-4
                rounded-full
                border border-gray-300
                grid
                place-content-center
              "
              :class="{ 'bg-green-500': paymentCardAdded }"
            >
              <svg
                v-if="!paymentCardAdded"
                class="inline-block align-middle"
                width="24"
                height="24"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M0 4l0 0c-.01-1.11.89-2.01 1.99-2.01h12l-.01 0c1.1-.01 2 .89 2 1.99v8 0c0 1.1-.9 2-2 2h-12l-.01-.001c-1.11-.01-2-.9-2-2.01 0 0 0 0 0 0v-8Zm2-1h-.01c-.56 0-1 .44-1 1 0 0 0 0 0 0v1h14V3.99v0c0-.56-.45-1-1-1h-12Zm13 4H1v5l0 0c0 .55.44.99 1 .99h12l-.01-.001c.55 0 1-.45 1-1v-5Z"
                />
                <path
                  d="M2 10l0 0c-.01-.56.44-1.01.99-1.01h1l-.01 0c.55-.01 1 .44 1 .99v1 0c0 .55-.45 1-1 1h-1l-.01-.001c-.56-.01-1-.45-1-1.01 0 0 0 0 0 0v-1Z"
                />
              </svg>
              <SVGs :name="'check'" v-if="paymentCardAdded" />
            </div>
            <div class="pl-6">
              <div class="text-md font-medium font-montserrat">
                Add payment card
              </div>
              <div v-if="!paymentCardAdded" class="text-sm mt-1 opacity-50">
                Add payment card to transact
              </div>
              <div v-else class="text-sm mt-1 opacity-50">
                Payment card added
              </div>
            </div>
          </div>
          <div
            v-if="profileProgress === 100"
            class="flex items-center py-4 md:px-3 cursor-pointer"
            @click="$router.push({ name: 'sendmoney-recipient' })"
          >
            <router-link
              :to="{ name: 'sendmoney-recipient' }"
              class="
                block
                w-full
                px-4
                py-2
                text-sm text-center
                rounded-md
                font-montserrat font-bold
                bg-yellow-300
              "
            >
              Send money
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <AddCardModal v-show="showAddCardModal" />
    <ThinSpin v-if="verifyIsLoading" />
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref } from '@vue/reactivity'
import SVGs from '@/components/partials/SVGs.vue'
import ThinSpin from '@/components/partials/ThinSpin.vue'
import veriff from '@/composables/veriff'
import AddCardModal from '@/components/desktop/AddCardModal.vue'
import definedConstants from '@/composables/definedConstants'
import { useRouter } from 'vue-router'
import utils from '@/composables/utils'
import { watch } from '@vue/runtime-core'

export default {
  components: { SVGs, AddCardModal, ThinSpin },
  setup() {
    const store = useStore()
    const showOnboard = ref(true)
    const router = useRouter()

    const accountActivated = computed(
      () => store.state.profile.accountActivated,
    )
    const accountVerified = computed(() => store.state.profile.accountVerified)
    const paymentCardAdded = computed(
      () => store.state.profile.paymentCardAdded,
    )
    const profileProgress = computed(() => store.state.profile.profileProgress)
    const userDetails = computed(() => store.state.auth.userDetails)

    const kycSession = computed(() => store.state.verify.kycSession)
    const token = computed(() => store.state.auth.token)
    const { setRequestConfig } = utils()
    const config = setRequestConfig(token.value, userDetails.value.jwt)

    const verifyIsLoading = computed(() => store.state.verify.verifyIsLoading)

    const showAddCardModal = computed(
      () => store.state.paymentInfo.showAddCardModal,
    )

    const fetchProfileDetails = () => {
      const userID = userDetails.value.userID
      const payload = {
        userID,
        config,
      }
      store.dispatch('auth/fetchProfileDetails', payload)
    }

    const veriffURL = computed(() => store.state.verify.veriffURL)
    const { setRequestData, setVeriffPerson, createFrame, status } = veriff()

    const { appBaseURL } = definedConstants()
    const currentRoute = router.currentRoute.value.path
    const redirectUrl = `${appBaseURL}${currentRoute}`

    const verify = async () => {
      const person = setVeriffPerson(userDetails.value.name)
      const body = {
        redirectUrl,
        person,
      }
      store.dispatch('verify/setVerifyIsLoadingTrue')
      await store.dispatch('verify/setVeriffSession', body)
      await store.dispatch(
        'verify/setKYCSession',
        setRequestData(userDetails.value.userID, kycSession.value, config),
      )
      await createFrame(veriffURL.value)
    }
    watch(
      () => status.value,
      () => {
        if (status.value === 'FINISHED') {
          fetchProfileDetails()
        }
      },
    )

    const openAddCardModal = () => {
      if (!paymentCardAdded.value) {
        store.dispatch('paymentInfo/openAddCardModal')
      }
    }

    return {
      verify,
      showOnboard,
      profileProgress,
      accountVerified,
      accountActivated,
      paymentCardAdded,
      showAddCardModal,
      openAddCardModal,
      verifyIsLoading,
    }
  },
}
</script>
