<template>
  <AuthWrapper>
    <div class="block my-6">
      <div class="font-montserrat text-2xl font-bold">Forgot password</div>
      <div class="text-sm text-gray-700 my-4">
        Don't have an account?
        <router-link :to="{ name: 'signup' }" class="text-blue-500"
          >Sign up</router-link
        >
      </div>
    </div>
    <!-- form -->
    <Alert alertType="error" v-if="getError()">{{ getError() }}</Alert>
    <form @submit.prevent="getCode()" autocomplete="off" class="block py-4">
      <div class="mb-4">
        <div
          class="border px-4 pt-2 pb-2 rounded-md"
          :class="{
            'border-red-300 bg-red-50': getFormErrorsByField('phone', false)
              .length,
          }"
        >
          <div class="text-xs text-gray-400">PHONE NUMBER</div>
          <div class="flex">
            <div class="pr-1">
              <CallingCode />
            </div>
            <div class="">
              <input
                required
                v-model="phone"
                :class="{
                  'bg-red-50': getFormErrorsByField('phone', false).length,
                }"
                @change="getFormErrorsByField('phone', true)"
                @keyup="getFormErrorsByField('phone', true)"
                class="outline-none"
                placeholder="Phone number"
              />
            </div>
          </div>
        </div>
        <div
          v-for="(error, index) in getFormErrorsByField('phone', false)"
          :key="index"
          class="text-sm text-red-500"
        >
          {{ error }}
        </div>
      </div>
      <button
        type="submit"
        class="
          block
          px-4
          py-2
          w-full
          rounded-md
          bg-yellow-300
          font-medium
          text-center
          font-montserrat
        "
      >
        Next
      </button>
      <div class="text-sm py-4 inline-block mt-2 text-gray-700">
        Already have an account?
        <router-link :to="{ name: 'login' }" class="text-blue-500"
          >Login</router-link
        >
      </div>
    </form>
  </AuthWrapper>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed } from '@vue/runtime-core'
import CallingCode from '@/components/partials/CallingCode.vue'
import Alert from '@/components/partials/Alert.vue'
import utils from '@/composables/utils'
import { useRouter } from 'vue-router'
import AuthWrapper from './AuthWrapper.vue'

export default {
  components: {
    AuthWrapper,
    CallingCode,
    Alert,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const phone = ref('')
    const token = computed(() => store.state.auth.token)
    const formErrors = computed(() => store.state.auth.formErrors)
    const { setRequestConfig, setFormErrors } = utils()
    const success = computed(() => store.state.auth.success)
    const countryDetails = computed(() => store.state.countries.countryDetails)
    const error = computed(() => store.state.auth.error)

    const getCode = async () => {
      let phoneNumber = `+${countryDetails.value.prefix}${phone.value}`
      const body = {
        phone: phoneNumber,
      }
      const config = setRequestConfig(token.value, null)
      const payload = {
        body,
        config,
      }
      store.dispatch('auth/setLoadingTrue')
      await store.dispatch('auth/getCode', payload)
      if (success.value !== '') {
        if (success.value.component === 'forgotPassword') {
          router.push({ name: 'change' })
        }
      }
    }

    const getFormErrorsByField = (field, isToClearErrors) => {
      if (isToClearErrors) {
        store.dispatch('auth/clearFormFieldError', field)
      } else if (formErrors.value !== null && !isToClearErrors) {
        if (formErrors.value.component === 'forgotPassword') {
          return setFormErrors(formErrors.value, field)
        } else {
          return []
        }
      } else {
        return []
      }
    }

    const getError = () => {
      if (error.value !== '') {
        if (error.value.component === 'forgotPassword') {
          return error.value.data.message
        }
      }
    }

    return {
      phone,
      getCode,
      getError,
      getFormErrorsByField,
    }
  },
}
</script>
