import { ref } from '@vue/runtime-core'

const definedConstants = () => {
  const defaultCountryName = `Kenya`
  const restCountriesFlagBaseURL = `https://helasend.s3.eu-west-1.amazonaws.com/img/flags/`
  const apiBaseURL = `https://api.helasend.com`
  const appBaseURL = `https://app.helasend.com`
  // const appBaseURL = `http://localhost:8080`
  const signDURL = 'https://api.integration.signd.io/internal/handshake'
  const veriffSessionURL = 'https://stationapi.veriff.com/v1/sessions/'
  const veriffCallBackURL =
    'https://switch.tuma.com/api/veriff/kyc/notifications'

  const currencyOptions = {
    locale: undefined,
    currency: 'EUR',
    currencyDisplay: 'hidden',
    valueRange: undefined,
    precision: 2,
    hideCurrencySymbolOnFocus: true,
    hideGroupingSeparatorOnFocus: false,
    hideNegligibleDecimalDigitsOnFocus: false,
    autoDecimalDigits: false,
    exportValueAsInteger: false,
    autoSign: false,
    useGrouping: true,
  }

  const passwordRules = ref({
    lowerCaseLetter: {
      message: 'Your password must contain at least one lower case letter.',
      passed: false,
    },
    upperCaseLetter: {
      message: 'Your password must contain at least one upper case letter.',
      passed: false,
    },
    digit: {
      message: 'Your password must contain at least one digit.',
      passed: false,
    },
    specialCharacter: {
      message: 'Your password must contain at least one special character.',
      passed: false,
    },
    length: {
      message: 'Your password must be at least 8 characters long',
      passed: false,
    },
  })

  const profilePaymentRoutesArray = [
    'sendmoney-recipient',
    'dashboard-recipients',
    'dashboard-transactions',
    'home',
    'add-paymentInfo',
    'profile',
  ]

  const sendMoneyReviewRoutesArray = [
    'sendmoney-recipient',
    'sendmoney-complete',
    'sendmoney-amount',
    'add-paymentInfo',
    'home',
  ]

  const sendMoneyReviewPathArray = [
    '/home',
    '/sendmoney/recipient',
    '/sendmoney/amount',
  ]

  return {
    signDURL,
    appBaseURL,
    apiBaseURL,
    passwordRules,
    currencyOptions,
    veriffSessionURL,
    veriffCallBackURL,
    defaultCountryName,
    restCountriesFlagBaseURL,
    sendMoneyReviewPathArray,
    profilePaymentRoutesArray,
    sendMoneyReviewRoutesArray,
  }
}
export default definedConstants
