import { createRouter, createWebHistory } from 'vue-router'
import Help from '../views/Help.vue'
import Profile from '../views/profile/Profile.vue'
import ProfileDetails from '../views/profile/ProfileDetails.vue'
import ProfilePayment from '../views/profile/ProfilePayment.vue'
import AddPaymentInfo from '../views/profile/AddPaymentInfo.vue'
import Login from '../views/auth/Login.vue'
import Signup from '../views/auth/Signup.vue'
import GetOTP from '../views/auth/GetOTP.vue'
import Change from '../views/auth/Change.vue'
import ResendCode from '../views/auth/ResendCode.vue'
import Verify from '../views/auth/Verify.vue'
import Home from '../views/Dashboard/Home.vue'
import Transaction from '../views/Dashboard/Transaction.vue'
import DashboardTransactions from '../views/Dashboard/Transactions.vue'
import DashboardRecipients from '../views/Dashboard/Recipients.vue'
import TransactionAmounts from '../views/desktop/sendmoney/TransactionAmounts.vue'
import RecipientDesktopView from '../views/desktop/sendmoney/Recipient.vue'
import Review from '../views/desktop/sendmoney/Review.vue'
import SendMoneyComplete from '../views/desktop/sendmoney/CompleteTransaction.vue'
import AddRecipient from '../views/recipients/AddRecipient.vue'
import store from '../store'

const routes = [
  //begin auth routes
  {
    path: '/',
    name: 'login',
    component: Login,
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
  },
  {
    path: '/otp',
    name: 'otp',
    component: GetOTP,
  },
  {
    path: '/change/password',
    name: 'change',
    component: Change,
  },
  {
    path: '/verify',
    name: 'verify',
    component: Verify,
  },
  {
    path: '/resend/code',
    name: 'resend-code',
    component: ResendCode,
  }, //end of auth routes

  //begin profile routes
  {
    path: '/account',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account/details',
    name: 'profile-details',
    component: ProfileDetails,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account/payment',
    name: 'profile-payment',
    component: ProfilePayment,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/add/payment-info/:redirectPath',
    name: 'add-paymentInfo',
    component: AddPaymentInfo,
    meta: {
      requiresAuth: true,
    },
  }, //end of profile routes

  //recipient routes
  {
    path: '/add/recipient/:returnUrl',
    name: 'add-recipient',
    component: AddRecipient,
    meta: {
      requiresAuth: true,
    },
  }, //end of recipient routes

  //begin dashboard routes mobile
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/transactions',
    name: 'dashboard-transactions',
    component: DashboardTransactions,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/recipients',
    name: 'dashboard-recipients',
    component: DashboardRecipients,
    meta: {
      requiresAuth: true,
    },
  },
  //begin transaction routes mobile
  {
    path: '/send/transaction',
    name: 'transaction',
    component: Transaction,
    meta: {
      requiresAuth: true,
    },
  },

  //begin transaction routes desktop
  {
    path: '/sendmoney/amount',
    name: 'sendmoney-amount',
    component: TransactionAmounts,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sendmoney/recipient',
    name: 'sendmoney-recipient',
    component: RecipientDesktopView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sendmoney/review',
    name: 'sendmoney-review',
    component: Review,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/sendmoney/complete',
    name: 'sendmoney-complete',
    component: SendMoneyComplete,
    meta: {
      requiresAuth: true,
    },
  },
  //end transaction routes desktop

  {
    path: '/help',
    name: 'help',
    component: Help,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const authenticatedUser = store.state.auth.authenticated
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  if (requiresAuth && !authenticatedUser) next('/')
  else next()
})

export default router
