<template>
  <AuthWrapper>
    <div class="block my-6 text-center">
      <div class="font-montserrat text-2xl font-bold">Enter password</div>
      <div class="text-sm text-gray-500 my-4">
        Enter new password for your account
      </div>
    </div>
    <!-- form -->
    <form @submit.prevent="changePassword()" class="block py-4">
      <div class="block mb-8">
        <div class="border px-4 pt-2 pb-2 rounded-md">
          <div class="text-xs text-gray-400">ENTER OTP CODE</div>
          <input
            required
            v-model="otp"
            class="outline-none w-full"
            placeholder="XXXX"
            type="tel"
            autocomplete="false"
          />
        </div>
        <div class="text-red-500 text-sm px-4">{{ getError() }}</div>
        <div class="mt-2 px-4 text-xs text-gray-400 italic">
          Enter the code you received on your email or phone number to verify.
        </div>
      </div>
      <div class="block mb-8">
        <div class="border px-4 pt-2 pb-2 rounded-md">
          <div class="text-xs text-gray-400">ENTER PASSWORD</div>
          <input
            v-model="password"
            class="outline-none w-full"
            placeholder="••••••••"
            type="password"
            autocomplete="false"
          />
        </div>
        <div v-if="showPasswordErrors()">
          <div
            v-for="(error, index) in passwordRules"
            :key="index"
            class="mt-2 px-4 text-xs text-red-400 flex"
            :class="{ 'text-green-500': error.passed }"
          >
            <span
              class="
                w-4
                h-4
                mr-2
                bg-red-200
                rounded-full
                inline-flex
                items-center
                justify-center
              "
              :class="{ 'bg-green-200': error.passed }"
              ><svg
                v-if="error.passed"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            <div>
              {{ error.message }}
            </div>
          </div>
        </div>
        <div v-else class="mt-2 px-4 text-xs text-gray-400 italic">
          Password must contain an uppercase letter, a special character, a
          number and be a minimum of 8 characters
        </div>
      </div>
      <div class="mb-8">
        <div
          class="block"
          :class="{
            'border-red-300 bg-red-50': getFormErrorsByField(
              'confirmPassword',
              false,
            ).length,
          }"
        >
          <div class="border px-4 pt-2 pb-2 rounded-md">
            <div class="text-xs text-gray-400">CONFIRM PASSWORD</div>
            <input
              v-model="confirm"
              :class="{
                'bg-red-50': getFormErrorsByField('confirmPassword', false)
                  .length,
              }"
              @change="getFormErrorsByField('confirmPassword', true)"
              @keyup="getFormErrorsByField('confirmPassword', true)"
              class="outline-none w-full"
              placeholder="••••••••"
              type="password"
              autocomplete="false"
            />
          </div>
        </div>
        <div
          v-for="(error, index) in getFormErrorsByField(
            'confirmPassword',
            false,
          )"
          :key="index"
          class="text-sm text-red-500"
        >
          {{ error }}
        </div>
      </div>
      <button
        type="submit"
        @click="login"
        class="
          block
          px-4
          py-2
          w-full
          rounded-md
          bg-yellow-300
          font-medium
          text-center
          font-montserrat
        "
      >
        Save password
      </button>
    </form>
  </AuthWrapper>

  <div
    v-if="showSuccessAlert"
    class="fixed left-0 top-0 w-full h-full z-10 transition-all"
  >
    <div
      @click="closeSuccessAlert()"
      class="absolute bottom-0 left-0 right-0 top-0 bg-black bg-opacity-50"
    ></div>
    <div
      class="
        absolute
        top-52
        left-2
        right-2
        bg-white
        rounded-lg
        overflow-auto
        max-w-xl
        mx-auto
        animate__animated animate__fadeInDown
      "
    >
      <div class="flex w-full justify-center py-4">
        <div
          class="
            flex
            items-center
            bg-green-100
            justify-center
            h-16
            w-16
            rounded-full
          "
        >
          <div class="text-green-500">
            <svg fill="currentColor" viewBox="0 0 20 20" class="h-14 w-14">
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <div
        class="
          flex
          w-full
          justify-center
          font-montserrat
          text-2xl
          font-medium
          text-gray-600
          tracking-wide
        "
      >
        Success!
      </div>
      <div class="font-montserrat text-xl text-gray-500 font-medium py-4">
        <div class="w-full flex justify-center">
          Password changed successfully. Proceed to login
        </div>
        <div class="w-full flex justify-center my-4">
          <button
            class="
              block
              p-2
              tracking-wider
              bg-accent
              rounded-md
              text-gray-700
              font-medium
              shadow-lg
            "
            @click="closeSuccessAlert()"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
import { computed, ref } from '@vue/runtime-core'
import utils from '@/composables/utils'
import definedConstants from '@/composables/definedConstants'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import AuthWrapper from './AuthWrapper.vue'
export default {
  components: { AuthWrapper },
  setup() {
    const store = useStore()
    const router = useRouter()
    const password = ref('')
    const otp = ref('')
    const confirm = ref('')
    const showSuccessAlert = ref(false)
    const token = computed(() => store.state.auth.token)
    const formErrors = computed(() => store.state.auth.formErrors)
    const { setRequestConfig, setFormErrors, validatePassword } = utils()

    const error = computed(() => store.state.auth.error)
    const success = computed(() => store.state.auth.success)

    const forgotPasswordUserDetails = computed(
      () => store.state.auth.forgotPasswordUserDetails,
    )

    const changePassword = async () => {
      if (validatePassword(password.value, passwordRules.value)) {
        const body = {
          phone: forgotPasswordUserDetails.value,
          otpCode: otp.value,
          password: password.value,
          confirmPassword: confirm.value,
        }
        const config = setRequestConfig(token.value, null)
        const payload = {
          body,
          config,
        }
        store.dispatch('auth/setLoadingTrue')
        await store.dispatch('auth/changePassword', payload)
        if (success.value !== '') {
          if (success.value.component === 'changePassword') {
            showSuccessAlert.value = true
          }
        }
      }
    }

    const getFormErrorsByField = (field, isToClearErrors) => {
      validatePassword(password.value, passwordRules.value)
      if (isToClearErrors) {
        store.dispatch('auth/clearFormFieldError', field)
      } else if (formErrors.value !== null && !isToClearErrors) {
        if (formErrors.value.component === 'changePassword') {
          return setFormErrors(formErrors.value, field)
        } else {
          return []
        }
      } else {
        return []
      }
    }

    const getError = () => {
      if (error.value !== '') {
        if (error.value.component === 'changePassword') {
          return error.value.message
        }
      }
    }

    const passwordFieldIsFocused = ref(false)

    const { passwordRules } = definedConstants()

    const focusPasswordField = () => {
      passwordFieldIsFocused.value = true
    }

    const showPasswordErrors = () => {
      return passwordFieldIsFocused.value || password.value.length > 0
    }

    const closeSuccessAlert = () => {
      showSuccessAlert.value = false
      router.push({ name: 'login' })
    }

    return {
      otp,
      confirm,
      password,
      getError,
      passwordRules,
      changePassword,
      showSuccessAlert,
      closeSuccessAlert,
      focusPasswordField,
      showPasswordErrors,
      getFormErrorsByField,
    }
  },
}
</script>
