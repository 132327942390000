<template>
  <TransactionsWrapper>
    <div class="relative">
      <div class="text-lg text-gray-600 font-montserrat font-medium">
        Review transaction
      </div>
      <SelectedRecipient />
      <Amount />
      <PaymentInfo v-if="paymentInfo !== null" />
      <Pay />
    </div>
  </TransactionsWrapper>
  <CVVModal v-if="showCVVModal" />
  <AddCardModal v-if="showAddCardModal" />
  <Spin v-if="loading" />
  <ErrorAlert :refreshParent="false" v-if="showErrorAlert && error !== null">{{
    getError()
  }}</ErrorAlert>
  <AlertSuccess v-if="showSuccessAlert" style="z-index: 2147483004"
    >Card added successfully</AlertSuccess
  >
  <ErrorAlert
    :refreshParent="true"
    v-if="showErrorAlert && error === null"
    style="z-index: 2147483004"
    >We are unable to verify your card. Please try again or contact
    support</ErrorAlert
  >
</template>

<script>
import TransactionsWrapper from './TransactionsWrapper'
import SelectedRecipient from '@/components/sendmoney-review/SelectedRecipient'
import Amount from '@/components/sendmoney-review/Amount'
import PaymentInfo from '@/components/sendmoney-review/PaymentInfo'
import Pay from '@/components/sendmoney-review/Pay'
import CVVModal from '@/components/sendmoney-review/CVVModal'
import AddCardModal from '@/components/desktop/AddCardModal'
import Spin from '@/components/partials/Spin'
// import ErrorAlert from '@/components/sendmoney-review/ErrorAlert'
import AlertSuccess from './AlertSuccess'
import ErrorAlert from './ErrorAlert'
import { useStore } from 'vuex'
import { computed, ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
export default {
  components: {
    Pay,
    Spin,
    Amount,
    CVVModal,
    ErrorAlert,
    PaymentInfo,
    AlertSuccess,
    AddCardModal,
    SelectedRecipient,
    TransactionsWrapper,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const status = ref(route.query.status)
    const showCVVModal = computed(() => store.state.paymentInfo.showCVVModal)
    const loading = computed(() => store.state.transactions.loading)
    const showAddCardModal = computed(
      () => store.state.paymentInfo.showAddCardModal,
    )
    const paymentInfo = computed(() => store.state.paymentInfo.paymentInfo)
    const showErrorAlert = computed(() => store.state.alerts.showErrorAlert)
    const showSuccessAlert = computed(() => store.state.alerts.showSuccessAlert)
    const cardAddUnsuccessful = computed(
      () => store.state.alerts.showErrorAlert,
    )
    const error = computed(() => store.state.transactions.error)

    const getError = () => {
      if (error.value !== null) {
        if (error.value.component === 'createTransaction') {
          return error.value.data.message
        }
      }
    }

    if (status.value !== undefined) {
      if (status.value === 'success') {
        store.dispatch('alerts/openSuccessAlert')
      } else if (status.value === 'failed') {
        store.dispatch('alerts/openErrorAlert')
      }
    }


    return {
      error,
      loading,
      getError,
      paymentInfo,
      showCVVModal,
      showErrorAlert,
      showSuccessAlert,
      showAddCardModal,
      cardAddUnsuccessful,
    }
  },
}
</script>
