import axios from 'axios'
import definedConstants from '@/composables/definedConstants'

const { apiBaseURL } = definedConstants()

const { defaultCountryName } = definedConstants()

const state = () => ({
  countries: [],
  baseCurrency: '',
  currencies:
    sessionStorage['currencies'] === undefined
      ? null
      : JSON.parse(sessionStorage['currencies']),
  destinationCurrencies:
    sessionStorage['destinationCurrencies'] === undefined
      ? null
      : JSON.parse(sessionStorage['destinationCurrencies']),
  sourceCurrencies:
    sessionStorage['sourceCurrencies'] === undefined
      ? null
      : JSON.parse(sessionStorage['sourceCurrencies']),
  defaultCountryDetails:
    sessionStorage['defaultCountryDetails'] === undefined
      ? null
      : JSON.parse(sessionStorage['defaultCountryDetails']),
  senderCurrencyDetails:
    sessionStorage['senderCurrencyDetails'] === undefined
      ? null
      : JSON.parse(sessionStorage['senderCurrencyDetails']),
  showCountryCodesDropDownMenu: false,
  countryDetails: {
    currency: 'AED',
    flag: 'https://restcountries.eu/data/are.svg',
    isoCode: 'ARE',
    name: 'United Arab Emirates',
    prefix: '971',
  },
  loading: false,
})

const actions = {
  async fetchCountries({ commit }, payload) {
    const token = payload
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
    try {
      const response = await axios.get(
        `${apiBaseURL}/v1/countries
          `,
        config,
      )
      commit('setCountries', response.data)
    } catch (error) {
      console.log(error)
    }
  },

  async fetchExchangeRates({ commit }, payload) {
    const token = payload.token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
      },
    }
    try {
      const response = await axios.get(`${apiBaseURL}/v1/exchangerates`, config)
      commit('setLoadingFalse')
      commit('setBaseCurrency', response.data.base)
      commit('setCurrencies', response.data.data)
      commit('setDefaultCountryDetails', response.data.data)
    } catch (error) {
      commit('setLoadingFalse')
      console.log(error)
    }
  },
  setCurrencies({ commit }, payload) {
    commit('setCurrencies', payload)
  },
  setExchangeRates({ commit }, payload) {
    const customerCountry = payload.customerCountry
    commit('setExchangeRates', customerCountry)
  },
  setCountryDetails({ commit }, payload) {
    commit('setCountryDetails', payload)
  },
  setBeneficiaryCurrencyDetails({ commit }, payload) {
    commit('setBeneficiaryCurrencyDetails', payload)
  },
  openCountryCodesDropDownMenu({ commit }) {
    commit('openCountryCodesDropDownMenu')
  },
  closeCountryCodesDropDownMenu({ commit }) {
    commit('closeCountryCodesDropDownMenu')
  },
  setLoadingTrue({ commit }) {
    commit('setLoadingTrue')
  },
}

const mutations = {
  setCountries(state, payload) {
    state.countries = payload
  },
  setDefaultCountryDetails(state, payload) {
    const defaultCountryDetails = payload.filter((item) => {
      return item.name.toLowerCase() === defaultCountryName.toLowerCase()
    })
    sessionStorage['defaultCountryDetails'] = JSON.stringify(
      defaultCountryDetails[0],
    )
    state.defaultCountryDetails = JSON.parse(
      sessionStorage['defaultCountryDetails'],
    )
  },
  setBaseCurrency(state, payload) {
    state.baseCurrency = payload
  },
  setCurrencies(state, payload) {
    const sourceCurrencies = payload.filter((currency) => {
      return currency.isSourceCountry === 1
    })
    sessionStorage['sourceCurrencies'] = JSON.stringify(sourceCurrencies)
    state.sourceCurrencies = JSON.parse(sessionStorage['sourceCurrencies'])

    const destinationCurrencies = payload.filter((currency) => {
      return currency.isDestinationCountry === 1
    })
    sessionStorage['destinationCurrencies'] = JSON.stringify(
      destinationCurrencies,
    )
    state.destinationCurrencies = JSON.parse(
      sessionStorage['destinationCurrencies'],
    )

    sessionStorage['currencies'] = JSON.stringify(payload)
    state.currencies = JSON.parse(sessionStorage['currencies'])
  },
  setExchangeRates(state, payload) {
    const currencies = state.currencies
    const customerCountry = payload
    const customerExchangeRate = currencies.filter((item) => {
      return item.name === customerCountry
    })
    if (customerExchangeRate[0] !== undefined) {
      sessionStorage['senderCurrencyDetails'] = JSON.stringify(
        customerExchangeRate[0],
      )
      state.senderCurrencyDetails = JSON.parse(
        sessionStorage['senderCurrencyDetails'],
      )
    }
  },

  openCountryCodesDropDownMenu(state) {
    state.showCountryCodesDropDownMenu = true
  },
  closeCountryCodesDropDownMenu(state) {
    state.showCountryCodesDropDownMenu = false
  },
  setCountryDetails(state, payload) {
    state.countryDetails = payload
  },
  setLoadingTrue(state) {
    state.loading = true
  },
  setLoadingFalse(state) {
    state.loading = false
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
