<template>
  <AuthWrapper>
    <div class="block my-6 text-center">
      <div class="font-montserrat text-2xl font-bold">Verify account</div>
      <div class="text-sm text-gray-500 my-4">
        Enter the code you received on your<br />email or phone number to
        verify.
      </div>
    </div>
    <div v-if="verifyUserDetails" class="block my-4 text-center">
      <div class="text-sm font-medium text-gray-700">Account</div>
      <div class="text-xs text-gray-500">
        ***********{{ getLastDigits(verifyUserDetails.phone, 2) }}
      </div>
    </div>
    <!-- form -->
    <form @submit.prevent="verifyCustomer()" class="block mb-4">
      <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
        <div class="text-xs text-gray-400">OTP CODE</div>
        <input
          required
          v-model="code"
          class="outline-none w-full"
          placeholder="XXXX"
          type="text"
        />
      </div>
      <div class="text-red-500 text-sm">{{ getError() }}</div>
      <button
        type="submit"
        class="
          block
          px-4
          py-2
          w-full
          rounded-md
          bg-yellow-300
          font-medium
          text-center
          font-montserrat
        "
      >
        Verify account
      </button>
    </form>
    <router-link to="/resend/code" class="text-blue-500"
      >Resend Code</router-link
    >
  </AuthWrapper>
</template>

<script type="text/javascript">
import { computed, ref } from '@vue/runtime-core'
import utils from '@/composables/utils'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import AuthWrapper from './AuthWrapper.vue'
export default {
  components: { AuthWrapper },
  setup() {
    const store = useStore()
    const router = useRouter()
    const code = ref('')
    const token = computed(() => store.state.auth.token)
    const verifyUserDetails = computed(() => store.state.auth.verifyUserDetails)
    const error = computed(() => store.state.auth.error)
    const success = computed(() => store.state.auth.success)

    if (token.value === null) {
      store.dispatch('auth/fetchToken')
    }
    const verifyCustomer = async () => {
      const userID = verifyUserDetails.value.userID
      const phone = verifyUserDetails.value.phone
      const body = {
        code: code.value,
        userID,
        phone,
      }
      const { setRequestConfig } = utils()
      const config = setRequestConfig(token.value, null)
      const payload = {
        body,
        config,
      }
      store.dispatch('auth/setLoadingTrue')
      await store.dispatch('auth/verifyCustomer', payload)
      if (success.value !== '') {
        if (success.value.component === 'verify') {
          router.push({ name: 'login' })
        }
      }
    }

    const getError = () => {
      if (error.value !== '') {
        if (error.value.component === 'verify') {
          return error.value.message
        }
      }
    }

    const getLastDigits = (str, end) => {
      if (str !== undefined) {
        return str.substring(str.length - end)
      }
    }

    return {
      code,
      getError,
      getLastDigits,
      verifyCustomer,
      verifyUserDetails,
    }
  },
}
</script>
