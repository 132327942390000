<template>
  <form
    @submit.prevent="goToNextRoute()"
    class="relative block bg-white shadow-xl mb-4 rounded-md border z-2"
  >
    <ChooseRecipient />

    <div class="flex divide-x border-b">
      <div class="w-1/2 px-2">
        <div
          class="
            text-xs text-gray-400 text-center
            font-montserrat font-bold
            pt-2
          "
        >
          SEND
        </div>
        <currency-input
          v-model="sendAmount"
          @keyup="
            handleCalculatePayoutAmount(
              isSameCountry(
                senderCurrencyDetails.name,
                setRecipientExchangeCountryName(
                  beneficiaryCurrencyDetails,
                  defaultCountryDetails,
                ),
              ),
            )
          "
          :options="setCurrencyOptionsForSender()"
          class="w-full px-2 py-2 text-xl outline-none text-center"
          placeholder="0.00"
        />
      </div>
      <div class="w-1/2 px-2">
        <div
          class="
            text-xs text-gray-400 text-center
            font-montserrat font-bold
            pt-2
          "
        >
          RECEIVE
        </div>
        <currency-input
          @keyup="
            handleCalculateSendAmount(
              isSameCountry(
                senderCurrencyDetails.name,
                setRecipientExchangeCountryName(
                  beneficiaryCurrencyDetails,
                  defaultCountryDetails,
                ),
              ),
            )
          "
          v-model="payoutAmount"
          :options="setCurrencyOptionsForRecipient()"
          class="w-full px-2 py-2 text-xl outline-none text-center"
          placeholder="0.00"
        />
      </div>
    </div>
    <div class="px-4 pb-4">
      <div class="block text-xs text-gray-500 py-2 text-center">
        Rate: 1 {{ userDetails.country.currency }} =
        {{
          calculateExchangeRate(
            isSameCountry(
              senderCurrencyDetails.name,
              setRecipientExchangeCountryName(
                beneficiaryCurrencyDetails,
                defaultCountryDetails,
              ),
            ),
            senderCurrencyDetails.marketRates,
            setRecipientExchangeRates(
              beneficiaryCurrencyDetails,
              defaultCountryDetails,
            ),
          )
        }}
        <span v-if="beneficiaryCurrencyDetails !== null">{{
          beneficiaryCurrencyDetails.currency
        }}</span>
        <span v-else>KES</span>
      </div>
      <button
        type="submit"
        class="
          w-full
          bg-yellow-300
          px-4
          py-2
          rounded-md
          font-montserrat font-medium
        "
      >
        Send money
      </button>
    </div>
  </form>
</template>

<script>
import CurrencyInput from '@/components/partials/CurrencyInput'
import ChooseRecipient from './ChooseRecipient.vue'
import { useStore } from 'vuex'
import utils from '@/composables/utils'
import definedConstants from '@/composables/definedConstants'
import createTransaction from '@/composables/createTransaction'
import { useRouter } from 'vue-router'
import { computed, ref } from '@vue/reactivity'

export default {
  components: { CurrencyInput, ChooseRecipient },
  setup() {
    const store = useStore()
    const router = useRouter()

    const transactionOnCreation = computed(
      () => store.state.transactions.transactionOnCreation,
    )
    const userDetails = computed(() => store.state.auth.userDetails)

    const selectedBeneficiary = computed(
      () => store.state.beneficiary.selectedBeneficiary,
    )
    const defaultCountryDetails = computed(
      () => store.state.countries.defaultCountryDetails,
    )
    const senderCurrencyDetails = computed(
      () => store.state.countries.senderCurrencyDetails,
    )
    const beneficiaryCurrencyDetails = computed(
      () => store.state.beneficiary.beneficiaryCurrencyDetails,
    )
    //amount beneficiary receives in beneficiary's currency
    const payoutAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.payoutAmount,
    )
    const sendAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.sendAmount,
    )

    const setExchangeRates = () => {
      const payload = {
        customerCountry: userDetails.value.country.name,
      }
      store.dispatch('countries/setExchangeRates', payload)
    }
    setExchangeRates()

    const { isSameCountry } = utils()

    const { currencyOptions } = definedConstants()

    const {
      setSendAmount,
      setPayoutAmount,
      calculateExchangeRate,
      setRecipientExchangeRates,
      setRecipientExchangeCountryName,
      setBeneficiaryCurrencyDetails,
    } = createTransaction()

    const currencyDetails = setBeneficiaryCurrencyDetails(
      beneficiaryCurrencyDetails.value,
      defaultCountryDetails.value,
    )

    const setCurrencyOptionsForSender = () => {
      return {
        ...currencyOptions,
        currency: userDetails.value.country.currency,
      }
    }
    const setCurrencyOptionsForRecipient = () => {
      const currency = currencyDetails.currency
      return {
        ...currencyOptions,
        currency,
      }
    }

    const handleCalculatePayoutAmount = (isSameCountry) => {
      const { AmountToPayout, transactionObject } = setPayoutAmount(
        isSameCountry,
        sendAmount.value,
        senderCurrencyDetails.value,
        currencyDetails,
        transactionOnCreation.value,
      )
      payoutAmount.value = AmountToPayout
      store.dispatch('transactions/buildTransactionObject', transactionObject)
    }

    const handleCalculateSendAmount = (isSameCountry) => {
      const { AmountToSend, transactionObject } = setSendAmount(
        isSameCountry,
        senderCurrencyDetails.value,
        payoutAmount.value,
        currencyDetails,
        transactionOnCreation.value,
      )
      sendAmount.value = AmountToSend
      store.dispatch('transactions/buildTransactionObject', transactionObject)
    }

    const goToNextRoute = () => {
      if (selectedBeneficiary.value !== null && sendAmount.value !== null) {
        return router.push({ name: 'sendmoney-review' })
      }
      if (selectedBeneficiary.value !== null) {
        return router.push({ name: 'sendmoney-amount' })
      } else {
        return router.push({ name: 'sendmoney-recipient' })
      }
    }
    return {
      sendAmount,
      userDetails,
      payoutAmount,
      goToNextRoute,
      isSameCountry,
      selectedBeneficiary,
      senderCurrencyDetails,
      defaultCountryDetails,
      calculateExchangeRate,
      beneficiaryCurrencyDetails,
      setRecipientExchangeRates,
      handleCalculateSendAmount,
      handleCalculatePayoutAmount,
      setCurrencyOptionsForSender,
      setCurrencyOptionsForRecipient,
      setRecipientExchangeCountryName,
    }
  },
}
</script>
