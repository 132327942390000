const state = () => ({
  showSuccessAlert: false,
  showErrorAlert: false,
})
const actions = {
  openSuccessAlert({ commit }) {
    commit('openSuccessAlert')
  },
  closeSuccessAlert({ commit }) {
    commit('closeSuccessAlert')
  },
  openErrorAlert({ commit }) {
    commit('openErrorAlert')
  },
  closeErrorAlert({ commit }) {
    commit('closeErrorAlert')
  },
}
const mutations = {
  openSuccessAlert(state) {
    state.showSuccessAlert = true
  },
  closeSuccessAlert(state) {
    state.showSuccessAlert = false
  },
  openErrorAlert(state) {
    state.showErrorAlert = true
  },
  closeErrorAlert(state) {
    state.showErrorAlert = false
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
