<template>
  <div class="block py-6 border-b">
    <div class="text-xs pb-2 text-gray-400 font-bold font-montserrat">
      AMOUNT
    </div>
    <div class="flex py-1">
      <div class="text-md text-gray-500">You send</div>
      <CurrencyInput
        disabled
        required
        class="
          text-md
          flex-grow
          text-right text-gray-700
          font-medium
          bg-white
          space-x-2
        "
        v-model="sendAmount"
        :options="setCurrencyOptionsForSender()"
      />
    </div>
    <div class="flex py-1">
      <div class="text-md text-gray-500">They receive</div>
      <CurrencyInput
        disabled
        required
        class="text-md flex-grow text-right text-gray-700 font-medium bg-white"
        v-model="payoutAmount"
        :options="setCurrencyOptionsForRecipient()"
      />
    </div>
    <div class="flex py-1">
      <div class="text-md text-gray-500">Fee</div>
      <CurrencyInput
        disabled
        required
        class="text-md flex-grow text-right text-gray-700 font-medium bg-white"
        v-model="fee"
        :options="setCurrencyOptionsForSender()"
      />
    </div>
    <div class="my-2 border-t border-dashed"></div>
    <div class="text-xs text-gray-400 font-inter mt-1">
      Rate: 1 {{ userDetails.country.currency }} =
      {{
        calculateExchangeRate(
          isSameCountry(
            senderCurrencyDetails.name,
            beneficiaryCurrencyDetails.name,
          ),
          senderCurrencyDetails.marketRates,
          getRecipientExchangeRates(),
        )
      }}
      {{ beneficiaryCurrencyDetails.currency }}
    </div>
    <div class="flex py-1">
      <div class="text-md text-gray-700">Amount deducted</div>
      <CurrencyInput
        disabled
        required
        class="
          text-md
          flex-grow
          text-right text-gray-700
          font-bold font-montserrat
          bg-white
        "
        v-model="payAmount"
        :options="setCurrencyOptionsForSender()"
      />
    </div>
    <div class="block ml-6 border-l px-6 py-6 hidden">
      <div class="text-xs text-gray-400 font-inter mb-4">
        Rate: 1 GHS = 18.34 KES
      </div>
      <div class="block py-2">
        <div class="text-sm text-gray-700 font-montserrat">
          Amount to be deducted
        </div>
        <input
          class="text-xl py-1 w-full outline-none font-inter"
          value="KES 123.00"
          placeholder="0"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyInput from '@/components/partials/CurrencyInput'
import definedConstants from '@/composables/definedConstants'
import { useStore } from 'vuex'
import { computed, ref } from '@vue/reactivity'
import utils from '@/composables/utils'
import createTransaction from '@/composables/createTransaction'

export default {
  components: { CurrencyInput },
  setup() {
    const store = useStore()

    const userDetails = computed(() => store.state.auth.userDetails)

    const transactionOnCreation = computed(
      () => store.state.transactions.transactionOnCreation,
    )
    const payAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.payAmount,
    )
    //amount beneficiary receives in beneficiary's currency
    const payoutAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.payoutAmount,
    )

    const sendAmount = ref(
      transactionOnCreation.value === null
        ? null
        : transactionOnCreation.value.sendAmount,
    )

    const senderCurrencyDetails = computed(
      () => store.state.countries.senderCurrencyDetails,
    )
    const fee = ref(
      senderCurrencyDetails.value === null
        ? null
        : senderCurrencyDetails.value.fee,
    )

    const beneficiaryCurrencyDetails = computed(
      () => store.state.beneficiary.beneficiaryCurrencyDetails,
    )

    const { currencyOptions } = definedConstants()

    const setCurrencyOptionsForSender = () => {
      return {
        ...currencyOptions,
        currency: userDetails.value.country.currency,
        currencyDisplay: 'code',
      }
    }
    const setCurrencyOptionsForRecipient = () => {
      return {
        ...currencyOptions,
        currency: beneficiaryCurrencyDetails.value.currency,
        currencyDisplay: 'code',
      }
    }

    const getRecipientExchangeRates = () => {
      return beneficiaryCurrencyDetails.value.rates
    }

    const { isSameCountry } = utils()
    const { calculateExchangeRate } = createTransaction()

    return {
      fee,
      payAmount,
      sendAmount,
      userDetails,
      payoutAmount,
      isSameCountry,
      calculateExchangeRate,
      senderCurrencyDetails,
      getRecipientExchangeRates,
      beneficiaryCurrencyDetails,
      setCurrencyOptionsForSender,
      setCurrencyOptionsForRecipient,
    }
  },
}
</script>
