import moment from 'moment'
import definedConstants from './definedConstants'

const utils = () => {
  const formatDateTime = (date, format) => {
    return moment(date).format(format)
  }

  const roundToTwo = (num) => {
    return +(Math.round(num + 'e+2') + 'e-2')
  }

  const isSameCountry = (senderCountry, recipientCountry) => {
    return senderCountry.toLowerCase() === recipientCountry.toLowerCase()
  }

  const { restCountriesFlagBaseURL } = definedConstants()
  const setCountryFlagURL = (countryCode) => {
    let countryCodeToLower = countryCode.toLowerCase()
    return `${restCountriesFlagBaseURL}${countryCodeToLower}.svg`
  }

  const setRequestConfig = (token, jwt) => {
    let headers = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    }
    if (jwt !== null) {
      headers = {
        Authorization: `Bearer ${token}`,
        JWTToken: jwt,
        Accept: 'application/json',
      }
    }
    const config = {
      headers,
    }
    return config
  }

  const setFormErrors = (formErrors, field) => {
    if (Object.prototype.hasOwnProperty.call(formErrors, 'errors')) {
      if (Object.prototype.hasOwnProperty.call(formErrors.errors, field)) {
        if (formErrors.errors[field] === undefined) {
          return []
        }
        return formErrors.errors[field]
      }
    }
    return []
  }

  const getUserInitials = (names) => {
    const namesArray = names.split(' ')
    if (namesArray.length < 1) {
      return ''
    }
    if (namesArray.length === 1) {
      return namesArray[0].slice(0, 1)
    }
    if (namesArray.length > 1) {
      return `${namesArray[0].slice(0, 1)}${namesArray[1].slice(0, 1)}`
    }
  }

  const getUserFullFirstNameAndLastNameInitial = (names) => {
    const namesArray = names.split(' ')
    if (namesArray.length < 1) {
      return ''
    }
    if (namesArray.length === 1) {
      return namesArray[0]
    }
    if (namesArray.length > 1) {
      return `${namesArray[0]} ${namesArray[1].slice(0, 1)}`
    }
  }

  const getLastXStringCharacters = (str, howMany) => {
    str += ''
    if (str) {
      return str.slice(-howMany)
    }
  }

  const getFirstXStringCharacters = (str, howMany) => {
    str += ''
    if (str) {
      return str.slice(0, howMany)
    }
  }

  const getBeneficiaryExchangeRate = (country, currencies) => {
    const beneficiaryExchangeRate = currencies.filter((item) => {
      return item.isoCode === country
    })
    return beneficiaryExchangeRate[0]
  }

  const validatePassword = (password, passwordRules) => {
    if (password) {
      if (password.search(/[a-z]/) < 0) {
        passwordRules.lowerCaseLetter.passed = false
      } else {
        passwordRules.lowerCaseLetter.passed = true
      }
      if (password.search(/[A-Z]/) < 0) {
        passwordRules.upperCaseLetter.passed = false
      } else {
        passwordRules.upperCaseLetter.passed = true
      }
      if (password.search(/[0-9]/) < 0) {
        passwordRules.digit.passed = false
      } else {
        passwordRules.digit.passed = true
      }
      if (password.search(/[^A-Za-z 0-9]/g) < 0) {
        passwordRules.specialCharacter.passed = false
      } else {
        passwordRules.specialCharacter.passed = true
      }
      if (password.length < 8) {
        passwordRules.length.passed = false
      } else {
        passwordRules.length.passed = true
      }
    }
    if (
      !passwordRules.lowerCaseLetter.passed ||
      !passwordRules.upperCaseLetter.passed ||
      !passwordRules.digit.passed ||
      !passwordRules.specialCharacter.passed ||
      !passwordRules.length.passed
    ) {
      return false
    }
    return true
  }

  const getAddCardRedirectUrl = (currentRoute) => {
    const { appBaseURL } = definedConstants()
    return `${appBaseURL}${currentRoute}`
  }

  const getPaymentMode = (payoutType) => {
    if (payoutType === 'Mobile Payments') {
      return 1
    } else if (payoutType === 'Banks Payments') {
      return 2
    }
  }

  return {
    roundToTwo,
    isSameCountry,
    setFormErrors,
    getPaymentMode,
    formatDateTime,
    getUserInitials,
    setRequestConfig,
    validatePassword,
    setCountryFlagURL,
    getAddCardRedirectUrl,
    getLastXStringCharacters,
    getFirstXStringCharacters,
    getBeneficiaryExchangeRate,
    getUserFullFirstNameAndLastNameInitial,
  }
}

export default utils
