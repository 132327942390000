<template>
  <div
    ref="prefix"
    class="flex items-center space-x-1 cursor-pointer"
    @click="openCountryCodesDropDownMenu()"
  >
    <div
      class="
        relative
        w-6
        h-4
        pb-2/3
        overflow-hidden
        rounded-sm
        shadow-md
        align-baseline
      "
    >
      <img
        :src="setCountryFlagURL(countryDetails.isoCode)"
        class="absolute h-full w-full border border-gray-200"
      />
    </div>
    <div>+{{ countryDetails.prefix }}</div>
    <SVGs name="chevron-down" class="w-7 h-6" />
    <div v-show="showCountryCodesDropDownMenu"></div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import SVGs from '@/components/partials/SVGs.vue'
import utils from '@/composables/utils'

import { useStore } from 'vuex'
export default {
  components: { SVGs },

  setup() {
    const store = useStore()

    const countryDetails = computed(() => store.state.countries.countryDetails)

    const showCountryCodesDropDownMenu = computed(
      () => store.state.countries.showCountryCodesDropDownMenu,
    )
    const openCountryCodesDropDownMenu = () => {
      store.dispatch('countries/openCountryCodesDropDownMenu')
    }

    const { setCountryFlagURL } = utils()

    return {
      countryDetails,
      setCountryFlagURL,
      openCountryCodesDropDownMenu,
      showCountryCodesDropDownMenu,
    }
  },
}
</script>
