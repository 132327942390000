<template>
  <AuthWrapper>
    <div class="block my-6 text-center">
      <HelasendSVG />
      <div class="font-montserrat text-2xl font-bold">Sign up!</div>
      <div class="text-sm text-gray-500 my-4">
        Already a member?
        <span @click="goToLogin()" class="text-blue-500 cursor-pointer"
          >Login</span
        >
      </div>
    </div>

    <!-- form -->
    <form @submit.prevent="signUp($event)" class="block py-4">
      <div class="mb-4">
        <div class="grid grid-cols-2 space-x-4">
          <div class="col-span-1">
            <div
              class="border px-4 pt-2 pb-2 rounded-md"
              :class="{
                'border-red-300 bg-red-50': getFormErrorsByField('name', false)
                  .length,
              }"
            >
              <div class="text-xs text-gray-400">FIRST NAME</div>
              <input
                v-model="firstName"
                class="outline-none w-full text-gray-700"
                @change="getFormErrorsByField('name', true)"
                @keyup="getFormErrorsByField('name', true)"
                :class="{
                  'bg-red-50': getFormErrorsByField('name', false).length,
                }"
                placeholder="James"
                required
              />
            </div>
          </div>
          <div class="col-span-1">
            <div
              class="border px-4 pt-2 pb-2 rounded-md"
              :class="{
                'border-red-300 bg-red-50': getFormErrorsByField('name', false)
                  .length,
              }"
            >
              <div class="text-xs text-gray-400">LAST NAME</div>
              <input
                v-model="lastName"
                class="outline-none w-full text-gray-700"
                :class="{
                  'border-red-300 bg-red-50': getFormErrorsByField(
                    'name',
                    false,
                  ).length,
                }"
                placeholder="Doe"
                required
              />
            </div>
          </div>
          <div
            v-for="(error, index) in getFormErrorsByField('name', false)"
            :key="index"
            class="text-sm text-red-500"
          >
            {{ error }}
          </div>
        </div>
      </div>

      <div class="border px-4 pt-2 pb-2 rounded-md mb-4">
        <div class="text-xs text-gray-400">EMAIL</div>
        <input
          class="outline-none w-full text-gray-700"
          v-model="email"
          placeholder="joe@example.com"
          type="email"
          autocomplete="off"
        />
      </div>

      <div class="mb-4">
        <div
          class="border px-4 pt-2 pb-2 rounded-md"
          :class="{
            'border-red-300 bg-red-50': getFormErrorsByField('phone', false)
              .length,
          }"
        >
          <div class="text-xs text-gray-400">PHONE NUMBER</div>
          <div class="flex">
            <div class="pr-1">
              <CallingCode />
            </div>
            <div class="">
              <input
                class="outline-none"
                :class="{
                  'bg-red-50': getFormErrorsByField('phone', false).length,
                }"
                @change="getFormErrorsByField('phone', true)"
                @keyup="getFormErrorsByField('phone', true)"
                v-model="phone"
                placeholder="7xxxxxxxx"
                type="tel"
                autocomplete="off"
                required
              />
            </div>
          </div>
        </div>
        <div
          v-for="(error, index) in getFormErrorsByField('phone', false)"
          :key="index"
          class="text-sm text-red-500"
        >
          {{ error }}
        </div>
      </div>

      <div class="block mb-4">
        <div
          class="border px-4 pt-2 pb-2 rounded-md"
          :class="{
            'border-red-300 bg-red-50': getFormErrorsByField('password', false)
              .length,
          }"
        >
          <div class="text-xs text-gray-400">CREATE PASSWORD</div>
          <div class="flex">
            <input
              :class="{
                'bg-red-50': getFormErrorsByField('password', false).length,
              }"
              @change="getFormErrorsByField('password', true)"
              @keyup="getFormErrorsByField('password', true)"
              @focus="focusPasswordField()"
              class="outline-none w-full"
              v-model="password"
              placeholder="••••••••"
              type="password"
              id="password"
              name="password"
              autocomplete="off"
              required
            />
            <EyeSVG />
          </div>
        </div>
        <div v-if="showPasswordErrors()">
          <div
            v-for="(error, index) in passwordRules"
            :key="index"
            class="mt-2 px-4 text-xs text-red-400 flex"
            :class="{ 'text-green-500': error.passed }"
          >
            <span
              class="
                w-4
                h-4
                mr-2
                bg-red-200
                rounded-full
                inline-flex
                items-center
                justify-center
              "
              :class="{ 'bg-green-200': error.passed }"
              ><svg
                v-if="error.passed"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                class="w-5 h-5"
                viewBox="0 0 24 24"
              >
                <path d="M20 6L9 17l-5-5"></path>
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="4"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
            <div>
              {{ error.message }}
            </div>
          </div>
        </div>
        <div v-else class="mt-2 px-4 text-xs text-gray-400 italic">
          Password must contain an uppercase letter, a special character, a
          number and be a minimum of 8 characters
        </div>
      </div>

      <div class="mb-8">
        <div
          class="border px-4 pt-2 pb-2 rounded-md"
          :class="{
            'border-red-300 bg-red-50': getFormErrorsByField(
              'referralCode',
              false,
            ).length,
          }"
        >
          <div class="text-xs text-gray-400">REFERRAL CODE</div>

          <input
            class="outline-none w-full"
            :class="{
              'bg-red-50': getFormErrorsByField('referralCode', false).length,
            }"
            @change="getFormErrorsByField('referralCode', true)"
            @keyup="getFormErrorsByField('referralCode', true)"
            v-model="referralCode"
            placeholder="8XyZ"
            type="text"
            autocomplete="off"
          />
        </div>
        <div
          v-for="(error, index) in getFormErrorsByField('referralCode', false)"
          :key="index"
          class="text-sm text-red-500"
        >
          {{ error }}
        </div>
        <div class="mt-2 px-4 text-xs text-gray-400 italic">Optional</div>
      </div>

      <div class="block mb-8">
        <label class="flex items-top gap-2 mb-2"
          ><input
            required
            @click="toggleTermsAndConditions()"
            type="checkbox"
            class="mt-1"
            v-model="termsAndConditions"
          />
          <span class="text-xs leading-normal text-gray-700"
            >I agree to Helasend's
            <a
              target="_blank"
              href="https://helasend.com/terms.html"
              class="text-blue-500"
              >Terms and Conditions, Refund Policy</a
            >
            and
            <a
              target="_blank"
              href="https://helasend.com/privacy.html"
              class="text-blue-500"
            >
              Privacy Policy
            </a>
          </span></label
        >
        <label class="flex items-top gap-2"
          ><input
            @click="toggleReceiveMarketingInfo()"
            type="checkbox"
            class="mt-1"
            v-model="receiveMarketingInfo"
          />
          <span class="text-xs leading-normal text-gray-700"
            >I would like to receive the latest information and offers from
            Helasend. I can opt-out anytime.</span
          ></label
        >
      </div>

      <div class="px-4 py-2 text-red-500 text-sm rounded-md">
        {{ getError() }}
      </div>

      <button
        type="submit"
        class="
          block
          px-4
          py-2
          w-full
          rounded-md
          bg-gray-200
          text-gray-400
          font-medium
          text-center
          font-montserrat
        "
        :class="{
          'bg-yellow-300 text-gray-900': termsAndConditions === true,
        }"
      >
        Get started
      </button>
    </form>
  </AuthWrapper>
</template>

<script type="text/javascript">
import { computed, ref } from '@vue/runtime-core'
import utils from '@/composables/utils'
import definedConstants from '@/composables/definedConstants'
import CallingCode from '@/components/partials/CallingCode.vue'
import EyeSVG from '@/components/partials/EyeSVG.vue'
import AuthWrapper from './AuthWrapper.vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import HelasendSVG from './HelasendSVG'
export default {
  components: {
    EyeSVG,
    CallingCode,
    AuthWrapper,
    HelasendSVG,
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const termsAndConditions = ref(false)
    const receiveMarketingInfo = ref(false)
    const country = ref('')
    const phone = ref('')
    const password = ref('')
    const passwordFieldIsFocused = ref(false)
    const firstName = ref('')
    const lastName = ref('')
    const email = ref('')
    const referralCode = ref('')
    const token = computed(() => store.state.auth.token)
    const error = computed(() => store.state.auth.error)
    const signupSuccessful = computed(() => store.state.auth.signupSuccessful)
    const currencies = computed(() => store.state.countries.currencies)
    const formErrors = computed(() => store.state.auth.formErrors)
    const countryDetails = computed(() => store.state.countries.countryDetails)

    store.dispatch('countries/fetchCountries', token.value)

    const toggleTermsAndConditions = () =>
      (termsAndConditions.value = !termsAndConditions.value)
    const toggleReceiveMarketingInfo = () =>
      (receiveMarketingInfo.value = !receiveMarketingInfo.value)

    const { setRequestConfig, setFormErrors, validatePassword } = utils()

    store.dispatch('countries/fetchExchangeRates', {
      token: token.value,
    })

    const { passwordRules } = definedConstants()

    const focusPasswordField = () => {
      passwordFieldIsFocused.value = true
    }

    const showPasswordErrors = () => {
      return passwordFieldIsFocused.value || password.value.length > 0
    }

    const getFormErrorsByField = (field, isToClearErrors) => {
      validatePassword(password.value, passwordRules.value)
      if (isToClearErrors) {
        store.dispatch('auth/clearFormFieldError', field)
      } else if (formErrors.value !== null && !isToClearErrors) {
        if (formErrors.value.component === 'signUp') {
          return setFormErrors(formErrors.value, field)
        } else {
          return []
        }
      } else {
        return []
      }
    }

    const signUp = async () => {
      if (validatePassword(password.value, passwordRules.value)) {
        const config = setRequestConfig(token.value, null)
        let phoneNumber = `+${countryDetails.value.prefix}${phone.value}`
        let name = `${firstName.value} ${lastName.value}`
        const body = {
          country: countryDetails.value.isoCode,
          referralCode: referralCode.value,
          password: password.value,
          phone: phoneNumber,
          token: token.value,
          email: email.value,
          name,
        }

        store.dispatch('auth/setLoadingTrue')
        await store.dispatch('auth/signUp', { body, config })
        if (signupSuccessful.value) {
          router.push({ name: 'verify' })
        }
      }
    }

    const goToLogin = () => {
      store.dispatch('countries/closeCountryCodesDropDownMenu')
      router.push({ name: 'login' })
    }

    const getError = () => {
      if (error.value !== '') {
        if (error.value.component === 'signUp') {
          return error.value.data.message
        }
      }
    }

    return {
      email,
      phone,
      signUp,
      country,
      getError,
      password,
      lastName,
      firstName,
      goToLogin,
      currencies,
      referralCode,
      passwordRules,
      validatePassword,
      focusPasswordField,
      termsAndConditions,
      showPasswordErrors,
      getFormErrorsByField,
      receiveMarketingInfo,
      toggleTermsAndConditions,
      toggleReceiveMarketingInfo,
    }
  },
}
</script>
