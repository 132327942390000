import axios from 'axios'
import definedConstants from '@/composables/definedConstants'

const { apiBaseURL } = definedConstants()

const state = () => ({
  beneficiaries: [],
  selectedBeneficiary:
    sessionStorage['selectedBeneficiary'] === undefined
      ? null
      : JSON.parse(sessionStorage['selectedBeneficiary']),
  beneficiaryCurrencyDetails:
    sessionStorage['beneficiaryCurrencyDetails'] === undefined
      ? null
      : JSON.parse(sessionStorage['beneficiaryCurrencyDetails']),
  showBeneficiariesModal: false,
  showAddBeneficiaryModal: false,
  success: '',
  loading: false,
  beneficiaryError: '',
  accordionIsOpenArray: [],
})
const actions = {
  async fetchBeneficiaries({ commit }, payload) {
    const token = payload.token
    const userID = payload.userID
    const jwt = payload.jwt
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        JWTToken: jwt,
        Accept: 'application/json',
      },
    }
    try {
      const response = await axios.get(
        `${apiBaseURL}/v1/beneficiaries/${userID}`,
        config,
      )
      commit('setLoadingFalse')
      if (response.data.code === 202) {
        commit('setBeneficiaries', response.data.data)
        commit('setAccordionIsOpenArray', response.data.data)
      }
    } catch (error) {
      commit('setLoadingFalse')
      console.log(error)
    }
  },
  async addBeneficiary({ commit }, payload) {
    try {
      const response = await axios.post(
        `${apiBaseURL}/v1/beneficiaries/create`,
        payload.body,
        payload.config,
      )
      commit('setLoadingFalse')
      if (response.data.code === 202) {
        commit('setSuccess', {
          component: 'addBeneficiary',
          data: response.data,
        })
        setTimeout(() => {
          commit('setSuccess', '')
        }, 15000)
        if (response.data.benefitiaryID) {
          payload.beneficiaryOnSelection.benefitiaryID =
            response.data.benefitiaryID
          commit('setSelectedBeneficiary', payload.beneficiaryOnSelection)
          commit('addBeneficiary', payload.beneficiaryOnSelection)
        }
      }
      if (response.data.code === 402) {
        commit('setError', {
          component: 'addBeneficiary',
          data: response.data,
        })
      }
    } catch (error) {
      console.log(error)
    }
  },

  async removeBeneficiary({ commit }, payload) {
    try {
      const response = await axios.post(
        `${apiBaseURL}/v1/beneficiaries/remove`,
        payload.body,
        payload.config,
      )
      commit('setLoadingFalse')
      if (response.data.code === 202) {
        commit('removeBeneficiary', payload.body.beneficiaryID)
      }
    } catch (error) {
      console.log(error)
    }
  },
  setSelectedBeneficiary({ commit }, payload) {
    commit('setSelectedBeneficiary', payload)
  },
  setBeneficiaryCurrencyDetails({ commit }, payload) {
    commit('setBeneficiaryCurrencyDetails', payload)
  },

  deselectBeneficiary({ commit }) {
    commit('deselectBeneficiary')
  },
  openBeneficiariesModal({ commit }) {
    commit('openBeneficiariesModal')
  },
  closeBeneficiariesModal({ commit }) {
    commit('closeBeneficiariesModal')
  },
  setSuccess({ commit }, payload) {
    commit('setSuccess', payload)
  },
  setLoadingTrue({ commit }) {
    commit('setLoadingTrue')
  },
  openAddBeneficiaryModal({ commit }) {
    commit('openAddBeneficiaryModal')
  },
  closeAddBeneficiaryModal({ commit }) {
    commit('closeAddBeneficiaryModal')
  },
  toggleAccordion({ commit }, payload) {
    commit('toggleAccordion', payload)
  },
  setAccordionIsOpenArray({ commit }, payload) {
    commit('setAccordionIsOpenArray', payload)
  },
}

const mutations = {
  setBeneficiaries(state, payload) {
    state.beneficiaries = payload
  },
  addBeneficiary(state, payload) {
    state.beneficiaries = [payload, ...state.beneficiaries]
  },
  setSelectedBeneficiary(state, payload) {
    sessionStorage.setItem('selectedBeneficiary', JSON.stringify(payload))
    state.selectedBeneficiary = JSON.parse(
      sessionStorage.getItem('selectedBeneficiary'),
    )
  },
  setBeneficiaryCurrencyDetails(state, payload) {
    sessionStorage['beneficiaryCurrencyDetails'] = JSON.stringify(payload)
    state.beneficiaryCurrencyDetails = JSON.parse(
      sessionStorage['beneficiaryCurrencyDetails'],
    )
  },
  deselectBeneficiary(state) {
    sessionStorage.removeItem('selectedBeneficiary')
    state.selectedBeneficiary = null
  },
  openBeneficiariesModal(state) {
    state.showBeneficiariesModal = true
  },
  closeBeneficiariesModal(state) {
    state.showBeneficiariesModal = false
  },
  removeBeneficiary(state, payload) {
    if (state.selectedBeneficiary !== null) {
      if (state.selectedBeneficiary.benefitiaryID === payload) {
        sessionStorage.removeItem('selectedBeneficiary')
        state.selectedBeneficiary = null
      }
    }
    const beneficiaries = state.beneficiaries.filter((item) => {
      return item.benefitiaryID !== payload
    })
    state.beneficiaries = beneficiaries
    if (!state.beneficiaries.length) {
      state.showBeneficiariesModal = false
      // state.showAddBeneficiaryModal = true
    }
  },
  setSuccess(state, payload) {
    state.success = payload
  },
  setLoadingTrue(state) {
    state.loading = true
  },
  setLoadingFalse(state) {
    state.loading = false
  },
  setError(state, payload) {
    state.beneficiaryError = payload
  },
  openAddBeneficiaryModal(state) {
    state.showAddBeneficiaryModal = true
  },
  closeAddBeneficiaryModal(state) {
    state.showAddBeneficiaryModal = false
  },

  setAccordionIsOpenArray(state, payload) {
    if (payload.length > 0) {
      let accordionIsOpenArray = []
      for (let i = 0; i <= payload.length; i++) {
        accordionIsOpenArray.push({ i, accordionIsOpen: false })
      }
      state.accordionIsOpenArray = accordionIsOpenArray
    }
  },

  toggleAccordion(state, payload) {
    let accordionIsOpen = state.accordionIsOpenArray
    accordionIsOpen[payload.index].accordionIsOpen = payload.openAccordion
    state.accordionIsOpenArray = accordionIsOpen
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
