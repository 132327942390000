<template>
  <div class="md:absolute md:flex md:w-full md:h-full">
    <div class="relative md:w-2/6 md:h-full md:bg-black">
      <div class="absolute top-0 left-0 right-0 bottom-0 bg-ob-1"></div>
    </div>
    <div class="md:w-4/6 relative my-4 px-6 py-4 md:max-w-lg max-w-xl mx-auto">
      <GoBack class="md:hidden" />
      <slot></slot>
      <div v-click-away="onClickAway" v-if="showCountryCodesDropDownMenu">
        <!-- mobile -->
        <CountryCodesMobile class="block sm:hidden" />
        <!-- desktop -->
        <CountryCodesDesktop
          class="hidden sm:block absolute top-52 -mt-1 right-5 left-5"
        />
      </div>
    </div>
  </div>
  <Spin v-if="loading" />
</template>

<script>
import Spin from '@/components/partials/Spin.vue'
import GoBack from '@/components/partials/GoBack'
import CountryCodesMobile from '@/components/mobile/CountryCodes.vue'
import CountryCodesDesktop from '@/components/desktop/CountryCodes.vue'
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
export default {
  components: { GoBack, Spin, CountryCodesMobile, CountryCodesDesktop },
  setup() {
    const store = useStore()
    const loading = computed(() => store.state.auth.loading)
    const showCountryCodesDropDownMenu = computed(
      () => store.state.countries.showCountryCodesDropDownMenu,
    )

    const onClickAway = () => {
      store.dispatch('countries/closeCountryCodesDropDownMenu')
    }
    return {
      loading,
      onClickAway,
      showCountryCodesDropDownMenu,
    }
  },
}
</script>
